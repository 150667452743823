import "./BusinessManager.css";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";
import BusinessManagerMobile from "./BusinessManagerMobile/BusinessManagerMobile";
import BusinessManagerDesktop from "./BusinessManagerDesktop/BusinessManagerDesktop";
import BusinessManagerHeader from "./BusinessManagerHeader/BusinessManagerHeader";
import BusinessManagerFooter from "./BusinessManagerFooter/BusinessManagerFooter";
import { useEffect, useState } from "react";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import userContextServiceInstance from "../../Shared/Services/user-context-service";
import Subscriptions from "./Subscriptions/Subscriptions"; 
import businessServiceInstance from "../../Shared/Services/business-service";
import businessSubscriptionServiceInstance from "../../Shared/Services/business-subscription-service";
import BusinessSelector from "./BusinessSelector/BusinessSelector";
import PurchaseSubscription from "./PurchaseSubscription/PurchaseSubscription";

const BusinessManager = (props) => {
    const [showLoading, setShowLoading] = useState(false);
    const [businessName, setBusinessName] = useState("");
    const [error, setError] = useState(false);
    const [showSubscriptions, setShowSubscriptions] = useState(false);
    const [showBusinessManager, setShowBusinessManager] = useState(false);
    const [showBusinessSelector, setShowBusinessSelector] = useState(false);
    const [showPurchaseSubscription, setShowPurchaseSubscription] = useState(false);
    const [subToPurchase, setSubToPurchase] = useState({});
    const [businesses, setBuinesses] = useState([]);
    const isMobile = useMediaQuery({ query: `(max-width: 1200px) or (max-height: 670px)` });
    const navigate = useNavigate();

    useEffect(() => {
        const getBusinessDetails = async (contextToken) => {
            //get businesses for user
            var businessResponse = await businessServiceInstance.getActiveBusinesses(contextToken);
            if(businessResponse.status !== 200){
                setError(true);
                setShowLoading(false);
                return;
            }

            const activeBusinesses = businessResponse.data;
            if(activeBusinesses.length === 0){
                setShowLoading(false);
                setShowSubscriptions(true);
                return;
            }
            
            if(activeBusinesses){
                //show business selector
                setBuinesses(activeBusinesses);
                setShowLoading(false);
                setShowBusinessSelector(true);
                return;
            }
        }

        const contextToken = userContextServiceInstance.getContextToken();
        if(contextToken === null || contextToken === undefined){
            setShowSubscriptions(true);
            return;
        }

        setShowLoading(true);
        getBusinessDetails(contextToken).catch(err => {
            console.error(err);
            setShowLoading(false);
            setError(true);
        });
    }, []);

    const setSubscriptionForPurhase = (subscription) => {
        setShowSubscriptions(false);
        setShowPurchaseSubscription(true);
        setSubToPurchase(subscription);
    }

    return (
        <div className="business-manager-container">
            { showSubscriptions && <Subscriptions setSubscriptionForPurhase={setSubscriptionForPurhase}/>}
            { showBusinessSelector && <BusinessSelector businesses={businesses} setShowBusinessSelector={setShowBusinessSelector} 
                                        setShowSubscriptions={setShowSubscriptions} setBusinessName={setBusinessName} 
                                        setShowBusinessManager={setShowBusinessManager} />}
            { showPurchaseSubscription && <PurchaseSubscription setShowPurchaseSubscription={setShowPurchaseSubscription} 
                                            setShowBusinessManager={setShowBusinessManager} subscriptionToPurchase={subToPurchase}
                                            setBusinessName={setBusinessName}/>}
            { showBusinessManager && 
            <>
                <BusinessManagerHeader businessName={businessName}/>
                {isMobile ? <BusinessManagerMobile /> : <BusinessManagerDesktop />}
                {/* <BusinessManagerFooter /> */}
            </>
            }
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            { showLoading && <LoadingOverlay />}
        </div>
    )
}

export default BusinessManager;