import { useState } from "react";
import "./Define.css";
import DefineOption from "./DefineOption";
import { Category, CurrencyExchange, Inventory2 } from "@mui/icons-material";
import IncomeTypes from "../IncomeTypes/IncomeTypes";
import ExpenseTypes from "../ExpenseTypes/ExpenseTypes";
import StockTypes from "../StockTypes/StockTypes";

const Define = () => {
    const [defineOption, setDefineOption] = useState("income-types");

    return (
        <div className="define-container">
            <div className="define-options-container">
                <DefineOption isActive={defineOption === "income-types"} title="Income Types" onClick={() => setDefineOption("income-types")}>
                    <CurrencyExchange fontSize="large" color="black"/>
                </DefineOption>
                <DefineOption isActive={defineOption === "expense-types"} title="Expense Types" onClick={() => setDefineOption("expense-types")}>
                    <Category fontSize="large" color="black"/>
                </DefineOption>
                <DefineOption isActive={defineOption === "inventory-types"} title="Inventory Types" onClick={() => setDefineOption("inventory-types")}>
                    <Inventory2 fontSize="large" color="black"/>
                </DefineOption>
            </div>
            <div className="define-option-content-container">
                {defineOption === "income-types" && <IncomeTypes />}
                {defineOption === "expense-types" && <ExpenseTypes />}
                {defineOption === "inventory-types" && <StockTypes />}
            </div>
        </div>
    )
}

export default Define;