import "./DesktopNavigationBar.css";
import { Balance, Category, Help, Paid, Inventory2, Savings, Summarize, Payments, Sell, PriceChange, ModeEdit, CreditScore } from "@mui/icons-material";
import Sales from "../Sales/Sales";
import Expenses from "../Expenses/Expenses";
import { useState } from "react";
import BusinessManagerHelp from "../Help/Help";
import Stock from "../Stock/Stock";
import StockTypes from "../StockTypes/StockTypes"; 
import ExpenseTypes from "../ExpenseTypes/ExpenseTypes";
import Assets from "../Assets/Assets";
import Overview from "../Overview/Overview";
import DesktopNavBarItem from "./DesktopNavBarItem";
import IncomeTypes from "../IncomeTypes/IncomeTypes";
import OtherIncome from "../OtherIncome/OtherIncome";
import Define from "../Define/Define";
import Liabilities from "../Liabilities/Liabilities";

const DesktopNavigationBar = (props) => {
    const [activeTab, setActiveTab] = useState("sales");

    const defineClick = () => {
        setActiveTab("define");
        props.setLoadableComponent(<Define/>);
    }

    const salesClick = () => {
        setActiveTab("sales");
        props.setLoadableComponent(<Sales/>);
    }

    const expensesClick = () => {
        setActiveTab("expenses");
        props.setLoadableComponent(<Expenses/>);
    }

    const stockClicked = () => {
        setActiveTab("stock");
        props.setLoadableComponent(<Stock />);
    }

    const helpClicked = () => {
        setActiveTab("help");
        props.setLoadableComponent(<BusinessManagerHelp />);
    }

    const assetsClicked = () => {
        setActiveTab("assets");
        props.setLoadableComponent(<Assets />);
    }

    const overviewClicked = () => {
        setActiveTab("overview");
        props.setLoadableComponent(<Overview />);
    }

    const otherIncomeClicked = () => {
        setActiveTab("other-income");
        props.setLoadableComponent(<OtherIncome />);
    }

    const liabilitiesClicked = () => {
        setActiveTab("liabilities");
        props.setLoadableComponent(<Liabilities />);
    }

    const getClassNames = (tabName) => {
        return activeTab === tabName ? "nav-icon-button-active" : "nav-icon-button";
    }
    
    return (
        <div className="business-manager-left-nav-bar">
            <div className="nav-top-section">
                <DesktopNavBarItem activeTab={activeTab} onClick={defineClick} tabName="define" text="Define">
                    <ModeEdit fontSize="small" color="black"/>
                </DesktopNavBarItem>
                <DesktopNavBarItem activeTab={activeTab} onClick={salesClick} tabName="sales" text="Sales">
                    <Sell fontSize="small" color="black"/>
                </DesktopNavBarItem>
                <DesktopNavBarItem activeTab={activeTab} onClick={otherIncomeClicked} tabName="other-income" text="Other Income">
                    <Paid fontSize="small" color="black"/>
                </DesktopNavBarItem>
                <DesktopNavBarItem activeTab={activeTab} onClick={expensesClick} tabName="expenses" text="Expenses">
                    <Payments fontSize="small" color="black"/>
                </DesktopNavBarItem>
                <DesktopNavBarItem activeTab={activeTab} onClick={stockClicked} tabName="stock" text="Inventory">
                    <Balance fontSize="small" color="black"/>
                </DesktopNavBarItem>
                <DesktopNavBarItem activeTab={activeTab} onClick={assetsClicked} tabName="assets" text="Assets">    
                    <Savings fontSize="small" color="black"/>
                </DesktopNavBarItem>
                <DesktopNavBarItem activeTab={activeTab} onClick={liabilitiesClicked} tabName="liabilities" text="Liabilities">    
                    <CreditScore fontSize="small" color="black"/>
                </DesktopNavBarItem>
                <DesktopNavBarItem activeTab={activeTab} onClick={overviewClicked} tabName="overview" text="Overview">
                    <Summarize fontSize="small" color="black"/>
                </DesktopNavBarItem>
                <DesktopNavBarItem activeTab={activeTab} onClick={helpClicked} tabName="help" text="Help">
                    <Help fontSize="small" color="black"/>
                </DesktopNavBarItem>
            </div>
        </div>
    );
}

export default DesktopNavigationBar;