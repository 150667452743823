import { useEffect, useState } from "react";
import "./Overview.css";
import ErrorDialog from "../../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import LoadingOverlay from "../../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import DataGrid from "../DataGrid/DataGrid";
import { convertDateObjectToString, convertDateStringToDateObject, getMonthDiff } from "../../../Shared/Utils/date-utils";
import { Typography } from "@mui/material";
import { calculateCompoundInterestValue, calculateDepreciatedValue, calculateSimpleInterestValue } from "../../../Shared/Utils/formula-utils";
import BasicButton from "../../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../../Shared/Components/Buttons/ButtonEnums";
import CustomDatePicker from "../../../Shared/Components/DatePicker/DatePicker";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import userContextServiceInstance from "../../../Shared/Services/user-context-service";
import assetServiceInstance from "../../../Shared/Services/BusinessManager/asset-service";
import saleServiceInstance from "../../../Shared/Services/BusinessManager/sale-service";
import expenseServiceInstance from "../../../Shared/Services/BusinessManager/expense-service";
import stockServiceInstance from "../../../Shared/Services/BusinessManager/stock-service";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import otherIncomeServiceInstance from "../../../Shared/Services/BusinessManager/other-income-service";
import liabilityServiceInstance from "../../../Shared/Services/BusinessManager/liability-service";

const Overview = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [saleRows, setSaleRows] = useState([]);
    const [saleHeaders, setSaleHeaders] = useState([]);
    const [otherIncomeRows, setOtherIncomeRows] = useState([]);
    const [otherIncomeHeaders, setOtherIncomeHeaders] = useState([]);
    const [expenseRows, setExpenseRows] = useState([]);
    const [expenseHeaders, setExpenseHeaders] = useState([]);
    const [stockRows, setStockRows] = useState([]);
    const [stockHeaders, setStockHeaders] = useState([]);
    const [assetRows, setAssetRows] = useState([]);
    const [assetHeaders, setAssetHeaders] = useState([]);
    const [liabilityRows, setLiabilityRows] = useState([]);
    const [liabilityHeaders, setLiabilityHeaders] = useState([]);
    const [incomeTotal, setIncomeTotal] = useState(0);
    const [otherIncomeTotal, setOtherIncomeTotal] = useState(0);
    const [expenseTotal, setExpenseTotal] = useState(0);
    const [stockTotal, setStockTotal] = useState(0);
    const [assetTotal, setAssetTotal] = useState(0);
    const [liabilityTotal, setLiabilityTotal] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [expanded, setExpanded] = useState("sales");
    const [noDates, setNoDates] = useState(false);
    const [currency, setCurrency] = useState("R");
    const [badDates, setBadDates] = useState(false);
    const [calculatedData, setCalculatedData] = useState(false);

    useEffect(() => {
        const saleGridHeaders = [
            {id: 1, value: "IncomeType", displayValue: "Income type", cellWidth: 150, validCellValue: null , isEditable: false},
            {id: 2, value: "Description", displayValue: "Description", cellWidth: 300, validCellValue: null, isEditable: false}, 
            {id: 3, value: "Date", displayValue: "Date", cellWidth: 120, validCellValue: null, isEditable: false},
            {id: 4, value: "Amount", displayValue: "Amount (R)", cellWidth: 150, validCellValue: null, isEditable: false},
            {id: 5, value: "Quantity", displayValue: "Quantity", cellWidth: 100, validCellValue: null, isEditable: false},
            {id: 6, value: "Total", displayValue: "Total (R)", cellWidth: 150, validCellValue: null, isEditable: false}
        ];

        const expenseGridHeaders = [
            {id: 1, value: "ExpenseType", displayValue: "Expense type", cellWidth: 150, validCellValue: null , isEditable: false},
            {id: 2, value: "Description", displayValue: "Description", cellWidth: 180, validCellValue: null, isEditable: false}, 
            {id: 3, value: "Date", displayValue: "Date", cellWidth: 120, validCellValue: null, isEditable: false},
            {id: 4, value: "Amount", displayValue: "Amount (R)", cellWidth: 150, validCellValue: null, isEditable: false}
        ];

        const stockGridHeaders = [
            {id: 1, value: "StockType", displayValue: "Stock type", cellWidth: 150, validCellValue: null, isEditable: false}, 
            {id: 2, value: "Description", displayValue: "Description", cellWidth: 180, validCellValue: null, isEditable: false}, 
            {id: 3, value: "Date", displayValue: "Date", cellWidth: 120, validCellValue: null, isEditable: false},
            {id: 4, value: "Amount", displayValue: "Amount (R)", cellWidth: 150, validCellValue: null, isEditable: false},
            {id: 5, value: "Quantity", displayValue: "Quantity", cellWidth: 100, validCellValue: null, isEditable: false},
            {id: 6, value: "Total", displayValue: "Total (R)", cellWidth: 150, validCellValue: null, isEditable: false},
        ];

        const assetGridHeaders = [
            {id: 1, value: "AssetType", displayValue: "Asset Type", cellWidth: 150, validCellValue: null, isEditable: false },
            {id: 2, value: "Description", displayValue: "Asset Description", cellWidth: 200, validCellValue: null, isEditable: false }, 
            {id: 3, value: "StartingAmount", displayValue: "Principle Value (R)", cellWidth: 150, validCellValue: null, isEditable: false },
            {id: 4, value: "StartDate", displayValue: "Date of purchase", cellWidth: 150, validCellValue: null, isEditable: false },
            {id: 5, value: "InterestType", displayValue: "Interest type", cellWidth: 150, validCellValue: null, isEditable: false },
            {id: 6, value: "CompoundPeriod", displayValue: "Compounding Period", cellWidth: 200, validCellValue: null, isEditable: false },                
            {id: 7, value: "InterestRate", displayValue: "Interest rate p.a.(%)", cellWidth: 150, validCellValue: null, isEditable: false },
            {id: 8, value: "FutureValue", displayValue: "Current Value (R)", cellWidth: 150, validCellValue: null, isEditable: false},
            {id: 9, value: "Comment", displayValue: "Comment", cellWidth: 300, validCellValue: null, isEditable: false},
        ];

        const otherIncomeGridHeaders = [
            {id: 1, value: "IncomeType", displayValue: "Income type", cellWidth: 150, validCellValue: null , isEditable: false},
            {id: 2, value: "Description", displayValue: "Description", cellWidth: 180, validCellValue: null, isEditable: false}, 
            {id: 3, value: "Date", displayValue: "Date", cellWidth: 120, validCellValue: null, isEditable: false},
            {id: 4, value: "Amount", displayValue: "Amount (R)", cellWidth: 150, validCellValue: null, isEditable: false}
        ];

        const liabilityGridHeaders = [
            {id: 1, value: "LiabilityType", displayValue: "Liability Type", cellWidth: 200, validCellValue: null, isEditable: false },
            {id: 2, value: "Description", displayValue: "Liability Description", cellWidth: 250, validCellValue: null, isEditable: false }, 
            {id: 3, value: "Amount", displayValue: "Amount (R)", cellWidth: 150, validCellValue: null, isEditable: false },
            {id: 4, value: "Date", displayValue: "Date", cellWidth: 150, validCellValue: null, isEditable: false },
            {id: 5, value: "Comment", displayValue: "Comment", cellWidth: 300, validCellValue: null, isEditable: false },
        ];

        setSaleHeaders(saleGridHeaders);
        setExpenseHeaders(expenseGridHeaders);
        setStockHeaders(stockGridHeaders);
        setAssetHeaders(assetGridHeaders);
        setOtherIncomeHeaders(otherIncomeGridHeaders);
        setLiabilityHeaders(liabilityGridHeaders);
    }, []);


    const sortGridRows = (rows) => {
        rows.sort((a, b) => {
            let date1 = convertDateStringToDateObject(a.rowData["Date"]).getTime();
            let date2 = convertDateStringToDateObject(b.rowData["Date"]).getTime();
            return date1 - date2;
        });

        return rows;
    }

    const sortAssetGridRows = (rows) => {
        rows.sort((a, b) => {
            let date1 = convertDateStringToDateObject(a.rowData["StartDate"]).getTime();
            let date2 = convertDateStringToDateObject(b.rowData["StartDate"]).getTime();
            return date1 - date2;
        });

        return rows;
    }

    const getSaleGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            case "Total":
                if(row.rowData["Amount"] === "" || row.rowData["Quantity"] === ""){
                    return "0";
                }
                let amount =  parseFloat(row.rowData["Amount"]);
                let quantity =  parseInt(row.rowData["Quantity"]);
                let total = amount * quantity;
                return Math.round((total + Number.EPSILON) * 100) / 100;
            default:
                return row.rowData[headerValue];
        }
    }

    const getExpenseGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            default:
                return row.rowData[headerValue];
        }
    }

    const getStockGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            case "Total":
                if(row.rowData["Amount"] === "" || row.rowData["Quantity"] === ""){
                    return "0";
                }
                let amount =  parseFloat(row.rowData["Amount"]);
                let quantity =  parseInt(row.rowData["Quantity"]);
                let total = amount * quantity;
                return Math.round((total + Number.EPSILON) * 100) / 100;
            
            default:
                return row.rowData[headerValue];
        }
    }

    const getOtherIncomeGridRowCellValue = (headerValue, row) => {
        return row.rowData[headerValue];
    }

    const getAssetGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            case "FutureValue":
                return calculateFutureAssetValue(row);
            default:
                return row.rowData[headerValue];
        }
    }

    const getLiabilityGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            default:
                return row.rowData[headerValue];
        }
    }

    const calculateFutureAssetValue = (row) => {
        if(row.rowData["InterestType"] === "" || row.rowData["InterestRate"] === ""){
            return "";
        }

        if(row.rowData["InterestType"] === "None"){
            return parseFloat(row.rowData["StartingAmount"]);
        }

        var interestType = row.rowData["InterestType"];
        var purchaseValue = parseFloat(row.rowData["StartingAmount"]);
        var startDate = convertDateStringToDateObject(row.rowData["StartDate"]);
        var interestRate = parseFloat(row.rowData["InterestRate"]);
        var currentDate = new Date();
        if(currentDate < startDate){
            return purchaseValue;
        }
        var numberMonths = getMonthDiff(startDate, currentDate);
        
        if(interestType === "Depreciation"){
            return calculateDepreciatedValue(purchaseValue, interestRate, numberMonths);
        }
        else if(interestType === "Simple"){
            return calculateSimpleInterestValue(purchaseValue, interestRate, numberMonths);
        }
        else if(interestType === "Compound"){
            var compoundPeriod = parseFloat(row.rowData["CompoundPeriod"]);
            return calculateCompoundInterestValue(purchaseValue, interestRate, numberMonths, compoundPeriod);
        }

        return "";
    }

    const assignRowValues = (row, header, newValue) => {
        row.rowData[header.value] = newValue;
        return row;
    }

    const canCommitRow = (row) => {
        return false;
    }

    const saveGridChanges = async (rows) => {
        //currently nothing to save. All data is readonly.
    }

    const deleteGridRows = async (guidList) => {
        //currently nothing to save. All data is readonly.
    }

    const getAssets = async (startDate, endDate) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        var payload = {
            BusinessEntityID: businessEntityId,
            StartDate: startDate,
            EndDate: endDate
        };

        var response = await assetServiceInstance.GetItemsForPeriod(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;
        var total = 0;
        let gridRows = [];
        items.forEach(item => {
            let date = convertDateObjectToString(item.startDate);
            var row = {
                id: item.entityID,
                rowData: { AssetType: item.assetType, Description: item.description, StartingAmount: item.startingAmount, StartDate: date, InterestType: item.interestType, CompoundPeriod: item.compoundPeriod, InterestRate: item.interestRate, Comment: item.comment }, 
                hasError: false,
                rowSelected: false,
                isEmpty: false,
                hasChanges: false,
                isComplete: true,
                isNewRow: false
            };
            var futureValue = getAssetGridRowCellValue("FutureValue", row);
            total += futureValue;

            gridRows.push(row);
        });

        gridRows = sortAssetGridRows(gridRows);
        setAssetRows(gridRows);
        setAssetTotal(total);
    }

    const getLiabilities = async (startDate, endDate) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        var payload = {
            BusinessEntityID: businessEntityId,
            StartDate: startDate,
            EndDate: endDate
        };

        var response = await liabilityServiceInstance.GetItemsForPeriod(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;
        var total = 0;
        let gridRows = [];
        
        items.forEach(item => {
            let date = convertDateObjectToString(item.date);
            var row = {
                id: item.entityID,
                rowData: { LiabilityType: item.liabilityType, Description: item.description, Amount: item.amount, Date: date, Comment: item.comment }, 
                hasError: false,
                rowSelected: false,
                isEmpty: false,
                hasChanges: false,
                isComplete: true,
                isNewRow: false
            };

            var futureValue = getLiabilityGridRowCellValue("Amount", row);
            total += futureValue;

            gridRows.push(row);
        });

        gridRows = sortGridRows(gridRows);
        setLiabilityRows(gridRows);
        setLiabilityTotal(total);
    }

    const getSaleItems = async (startDate, endDate) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        var payload = {
            BusinessEntityID: businessEntityId,
            StartDate: startDate,
            EndDate: endDate
        };

        var response = await saleServiceInstance.GetItemsForPeriod(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        var total = 0;
        items.forEach(item => {
            let date = convertDateObjectToString(item.incomeDate);
            var row = {
                id: item.entityID, 
                rowData: { IncomeType: item.incomeType, Description: item.description, Amount: item.amount, Date: date, Quantity: item.quantity, Comment: item.comment }, 
                hasError: false,
                rowSelected: false,
                isEmpty: false,
                hasChanges: false,
                isComplete: true,
                isNewRow: false
            };
            
            var itemTotal = getSaleGridRowCellValue("Total", row);
            total += itemTotal;
            gridRows.push(row);
        });

        gridRows = sortGridRows(gridRows);
        setSaleRows(gridRows);
        setIncomeTotal(total);
    }

    const getOtherIncomeItems = async (startDate, endDate) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        var payload = {
            BusinessEntityID: businessEntityId,
            StartDate: startDate,
            EndDate: endDate
        };

        var response = await otherIncomeServiceInstance.GetItemsForPeriod(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        var total = 0;
        items.forEach(item => {
            let date = convertDateObjectToString(item.incomeDate);
            var row = {
                id: item.entityID, 
                rowData: { IncomeType: item.incomeType, Description: item.description, Amount: item.amount, Date: date, Comment: item.comment }, 
                hasError: false,
                rowSelected: false,
                isEmpty: false,
                hasChanges: false,
                isComplete: true,
                isNewRow: false
            };
            
            total += item.amount;
            gridRows.push(row);
        });

        gridRows = sortGridRows(gridRows);
        setOtherIncomeRows(gridRows);
        setOtherIncomeTotal(total);
    }

    const getExpenses = async (startDate, endDate) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        var payload = {
            BusinessEntityID: businessEntityId,
            StartDate: startDate,
            EndDate: endDate
        };

        var response = await expenseServiceInstance.GetItemsForPeriod(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        var total = 0;

        items.forEach(item => {
            let date = convertDateObjectToString(item.expenseDate);
            var row = {
                id: item.entityID, 
                rowData: {ExpenseType: item.expenseType, Description: item.description, Amount: item.amount, Date: date, Comment: item.comment }, 
                hasError: false,
                rowSelected: false,
                isEmpty: false,
                hasChanges: false,
                isComplete: true,
                isNewRow: false
            };
            gridRows.push(row);
            var itemTotal = getExpenseGridRowCellValue("Amount", row);
            total += itemTotal;
        });

        gridRows = sortGridRows(gridRows);
        setExpenseRows(gridRows);
        setExpenseTotal(total);
    }

    const getStocks = async (startDate, endDate) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        var payload = {
            BusinessEntityID: businessEntityId,
            StartDate: startDate,
            EndDate: endDate
        };

        var response = await stockServiceInstance.GetItemsForPeriod(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        var total = 0;

        items.forEach(item => {
            let date = convertDateObjectToString(item.purchaseDate);
            var row = {
                id: item.entityID, 
                rowData: { StockType: item.stockType, Description: item.description, Amount: item.amount, Date: date, Quantity: item.quantity, Comment: item.comment }, 
                hasError: false,
                rowSelected: false,
                isEmpty: false,
                hasChanges: false,
                isComplete: true,
                isNewRow: false
            }
            gridRows.push(row);
            var itemTotal = getStockGridRowCellValue("Total", row);
            total += itemTotal;
        });

        gridRows = sortGridRows(gridRows);
        setStockRows(gridRows);
        setStockTotal(total);
    }

    const calculateClicked = async () => {
        if(!startDate || !endDate){
            setNoDates(true);
            return;
        }

        var start = startDate.add(1, "day").toDate();
        var end = endDate.add(1, "day").toDate();
        
        if(start >= end){
            setBadDates(true);
            return;
        }

        setLoading(true);

        await getAssets(start, end);
        await getSaleItems(start, end);
        await getOtherIncomeItems(start, end);
        await getExpenses(start, end);
        await getStocks(start, end);
        await getLiabilities(start, end);
        
        setCalculatedData(true);
        setLoading(false);
    }

    const getOperatingExpenses = () => {
        var operatingExpenses = [];
        expenseRows.forEach(row => {
            var itemType = row.rowData["ExpenseType"];
            var itemTotal = getExpenseGridRowCellValue("Amount", row);
            var added = false;
            operatingExpenses.forEach(expenseItem => {
                if(expenseItem.content === itemType){
                    expenseItem.value += itemTotal;
                    added = true;
                }
            });

            if(!added){
                operatingExpenses.push({ content: itemType, value: itemTotal });
            }
        });

        return operatingExpenses;
    }

    const getOtherIncomes = () => {
        var otherIncomes = [];
        otherIncomeRows.forEach(row => {
            var itemType = row.rowData["IncomeType"];
            var itemTotal = parseFloat(row.rowData["Amount"]);
            var added = false;
            otherIncomes.forEach(incomeItem => {
                if(incomeItem.content === itemType){
                    incomeItem.value += itemTotal;
                    added = true;
                }
            });

            if(!added){
                otherIncomes.push({ content: itemType, value: itemTotal });
            }
        });

        return otherIncomes;
    }

    const getAssetsForType = (assetType) => {
        var items = [];
        assetRows.forEach(row => {
            var itemType = row.rowData["AssetType"];
            var description = row.rowData["Description"];
            if(itemType === assetType){
                var itemTotal = parseFloat(row.rowData["StartingAmount"]);
                var added = false;
                items.forEach(item => {
                    if(item.content === description){
                        item.value += itemTotal;
                        added = true;
                    }
                });

                if(!added){
                    items.push({ content: description, value: itemTotal });
                }
            }
        });

        return items;
    }

    const getTotalAssetDepreciation = () => {
        var totalDepreciation = 0;
        assetRows.forEach(row => {
            var itemType = row.rowData["InterestType"];
            if(itemType === "Depreciation"){
                var futureValue = parseFloat(getAssetGridRowCellValue("FutureValue", row));
                var initialValue = parseFloat(row.rowData["StartingAmount"]);
                totalDepreciation += (initialValue - futureValue);
            }
        });

        totalDepreciation = Math.round((totalDepreciation + Number.EPSILON) * 100) / 100;
        return totalDepreciation;
    }

    const getLiabilitiesForType = (liabilityType) => {
        var items = [];
        liabilityRows.forEach(row => {
            var itemType = row.rowData["LiabilityType"];
            var description = row.rowData["Description"];
            if(itemType === liabilityType){
                var itemTotal = parseFloat(row.rowData["Amount"]);
                var added = false;
                items.forEach(item => {
                    if(item.content === description){
                        item.value += itemTotal;
                        added = true;
                    }
                });

                if(!added){
                    items.push({ content: description, value: itemTotal });
                }
            }
        });

        return items;
    }

    const incomeStatementClicked = async () => {
        if(!startDate || !endDate){
            setNoDates(true);
            return;
        }

        if(startDate >= endDate){
            setBadDates(true);
            return;
        }

        setLoading(true);
        var operatingExpenses = getOperatingExpenses();
        var otherIncomes = getOtherIncomes();

        var start = convertDateObjectToString(startDate.toDate());
        var end = convertDateObjectToString(endDate.toDate());
        var businessName = userContextServiceInstance.getBusinessName();
        var grossProfit = incomeTotal - stockTotal;
        var grossOperatingIncome = grossProfit + otherIncomeTotal;
        var netProfit = grossOperatingIncome - expenseTotal;

        const doc = new jsPDF();
        var incomeStatementTitle = "INCOME STATMENT OF " + businessName.toUpperCase() + " FOR PERIOD " + start + " - " + end;

        var tableBody = [
            [{content: incomeStatementTitle, colSpan: 2, rowSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } }],
            [{content: 'Sales'}, {content: incomeTotal}],
            [{content: 'Cost of sales'}, {content: '(' + stockTotal + ')'}],
            [{content: 'GROSS PROFIT', styles: {fontStyle: 'bold', textColor: 'red'}}, {content: grossProfit, styles: {fontStyle: 'bold', textColor: 'red'}}],
            [{content: 'Other income', styles: {fontStyle: 'bold'}}, {content: otherIncomeTotal, styles: {fontStyle: 'bold'}}],
        ];

        otherIncomes.forEach(item => {
            tableBody.push([{content: "    " + item.content}, {content: item.value}]);
        });

        tableBody.push(
            [{content: 'Gross operating income', styles: {fontStyle: 'bold'}}, {content: grossOperatingIncome, styles: {fontStyle: 'bold'}}],
            [{content: 'Operating expenses', styles: {fontStyle: 'bold'}}, {content: '('+ expenseTotal +')', styles: {fontStyle: 'bold'}}],
        )

        operatingExpenses.forEach(item => {
            tableBody.push([{content: "    " + item.content}, {content: item.value}]);
        });

        tableBody.push([{content: 'NET PROFIT', styles: {fontStyle: 'bold', textColor: 'red'}}, {content: netProfit, styles: {fontStyle: 'bold', textColor: 'red'}}]);

        autoTable(doc, {
            theme: 'grid',
            body: tableBody
        });

        doc.setFontSize(12);
        doc.text("Income statement generated by Tran Tech Solutions.", 10, 285);

        doc.save(businessName + " income statement.pdf");
        setLoading(false);
    }

    const balanceSheetClicked = () => {
        if(!startDate || !endDate){
            setNoDates(true);
            return;
        }

        if(startDate >= endDate){
            setBadDates(true);
            return;
        }

        setLoading(true);

        var start = convertDateObjectToString(startDate.toDate());
        var end = convertDateObjectToString(endDate.toDate());
        var businessName = userContextServiceInstance.getBusinessName();

        var currentAssets = getAssetsForType("Current Asset");
        var fixedAssets = getAssetsForType("Fixed Asset");
        var intangibleAssets = getAssetsForType("Intangible Asset");
        var totalDepreciation = getTotalAssetDepreciation();

        var currentLiabilities = getLiabilitiesForType("Current Liability");
        var longTermLiabilities = getLiabilitiesForType("Long Term Liability");

        var totalCurrentAssets = 0;
        var totalFixedAssets = 0;
        var totalIntangibleAssets = 0;
        var totalCurrentLiabilities = 0;
        var totalLongTermLiabilities = 0;

        var grossProfit = incomeTotal - stockTotal;
        var grossOperatingIncome = grossProfit + otherIncomeTotal;
        var netProfit = grossOperatingIncome - expenseTotal;

        const doc = new jsPDF();
        var balanceSheetTitle = "Balance Sheet of " + businessName + " for period " + start + " - " + end;

        var tableBody = [
            [{content: balanceSheetTitle, colSpan: 2, rowSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } }],
            [{content: 'Current Assets', colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontStyle: 'bold' }}],
        ];

        currentAssets.forEach(item => {
            totalCurrentAssets += item.value;
            tableBody.push([{content: item.content}, {content: item.value}]);
        });
        
        tableBody.push(
            [{content: 'Inventories'}, {content: stockTotal}],
            [{content: 'Total Current Assets', styles: { fontStyle: 'bold' }}, {content: totalCurrentAssets, styles: { fontStyle: 'bold' }}],
        );

        if(intangibleAssets.length > 0){
            tableBody.push(
                [{content: 'Intangible Assets', colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontStyle: 'bold' }}],
            );

            intangibleAssets.forEach(item => {
                totalIntangibleAssets += item.value;
                tableBody.push([{content: item.content}, {content: item.value}]);
            });

            tableBody.push(
                [{content: 'Total Intangible Assets', styles: { fontStyle: 'bold' }}, {content: totalIntangibleAssets, styles: { fontStyle: 'bold' }}],
            );
        }

        tableBody.push(
            [{content: 'Fixed Assets', colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontStyle: 'bold' }}]
        );

        fixedAssets.forEach(item => {
            totalFixedAssets += item.value;
            tableBody.push([{content: item.content}, {content: item.value}]);
        });

        var totalAssets = totalCurrentAssets + totalFixedAssets + totalIntangibleAssets - totalDepreciation;
        tableBody.push(
            [{content: 'Total Fixed Assets', styles: { fontStyle: 'bold' }}, {content: totalFixedAssets, styles: { fontStyle: 'bold' }}],
            [{content: 'Less: Accummulated depreciation'}, {content: "(" + totalDepreciation +")"}],
            [{content: 'Total Assets', styles: { fontStyle: 'bold' }}, {content: totalAssets, styles: { fontStyle: 'bold' }}],
            [{content: 'Current Liabilities', colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontStyle: 'bold' }}],
        );

        currentLiabilities.forEach(item => {
            totalCurrentLiabilities += item.value;
            tableBody.push([{content: item.content}, {content: item.value}]);
        });

        tableBody.push(
            [{content: 'Total Current Liabilities', styles: { fontStyle: 'bold' }}, {content: totalCurrentLiabilities, styles: { fontStyle: 'bold' }}],
            [{content: 'Long-term Liabilities', colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontStyle: 'bold' }}],
        );

        longTermLiabilities.forEach(item => {
            totalLongTermLiabilities += item.value;
            tableBody.push([{content: item.content}, {content: item.value}]);
        });

        var totalLiabilities = totalCurrentLiabilities + totalLongTermLiabilities;
        var totalOwnerEquity = totalAssets - totalLiabilities;
        var shareCapital = totalOwnerEquity - netProfit;

        tableBody.push(
            [{content: 'Total Long Term Liabilities', styles: { fontStyle: 'bold' }}, {content: totalLongTermLiabilities, styles: { fontStyle: 'bold' }}],
            [{content: 'Total Liabilities', styles: { fontStyle: 'bold' }}, {content: totalLiabilities, styles: { fontStyle: 'bold' }}],
            [{content: 'Owner\'s Equity', colSpan: 2, rowSpan: 1, styles: { halign: 'left', fontStyle: 'bold' }}],
            [{content: 'Share Capital'}, {content: shareCapital}],
            [{content: 'Retained Earnings'}, {content: netProfit}],
            [{content: 'Total Owner\'s Equity', styles: { fontStyle: 'bold' }}, {content: totalOwnerEquity, styles: { fontStyle: 'bold' }}],
            [{content: 'Total Liabilities & Equity', styles: { fontStyle: 'bold' }}, {content: totalAssets, styles: { fontStyle: 'bold' }}],
        );

        autoTable(doc, {
            theme: 'grid',
            body: tableBody
        });

        doc.setFontSize(12);
        doc.text("Balance Sheet generated by Tran Tech Solutions.", 10, 285);

        doc.save(businessName + " Balance Sheet.pdf");
        setLoading(false);
    }

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className="overview-container">
            <div className="accordion-container">
                <Accordion expanded={expanded === 'sales'} onChange={handleChange('sales')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>Sales summary</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Total for period: R{incomeTotal}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="overview-sales-grid-container">
                            <DataGrid gridRows={saleRows} gridHeaders={saleHeaders} saveGridChanges={saveGridChanges}
                                getCellValue={getSaleGridRowCellValue} canCommitRow={canCommitRow} useGridOperations={false}
                                deleteRows={deleteGridRows} sortGridRows={sortGridRows} assignRowValues={assignRowValues}/>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'otherIncomes'} onChange={handleChange('otherIncomes')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>Other Income summary</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Total for period: R{otherIncomeTotal}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="overview-other-incomes-grid-container">
                            <DataGrid gridRows={otherIncomeRows} gridHeaders={otherIncomeHeaders} saveGridChanges={saveGridChanges}
                                getCellValue={getOtherIncomeGridRowCellValue} canCommitRow={canCommitRow} useGridOperations={false}
                                deleteRows={deleteGridRows} sortGridRows={sortGridRows} assignRowValues={assignRowValues}/>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'expenses'} onChange={handleChange('expenses')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>Expense summary</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Total for period: R{expenseTotal}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="overview-expenses-grid-container">
                            <DataGrid gridRows={expenseRows} gridHeaders={expenseHeaders} saveGridChanges={saveGridChanges}
                                getCellValue={getExpenseGridRowCellValue} canCommitRow={canCommitRow} useGridOperations={false}
                                deleteRows={deleteGridRows} sortGridRows={sortGridRows} assignRowValues={assignRowValues}/>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'stocks'} onChange={handleChange('stocks')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>Stock summary</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Total for period: R{stockTotal}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="overview-stocks-grid-container">
                            <DataGrid gridRows={stockRows} gridHeaders={stockHeaders} saveGridChanges={saveGridChanges}
                                getCellValue={getStockGridRowCellValue} canCommitRow={canCommitRow} useGridOperations={false}
                                deleteRows={deleteGridRows} sortGridRows={sortGridRows} assignRowValues={assignRowValues}/>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'assets'} onChange={handleChange('assets')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>Asset summary</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Total for period: R{assetTotal}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="overview-assets-grid-container">
                            <DataGrid gridRows={assetRows} gridHeaders={assetHeaders} saveGridChanges={saveGridChanges}
                                getCellValue={getAssetGridRowCellValue} canCommitRow={canCommitRow} useGridOperations={false}
                                deleteRows={deleteGridRows} sortGridRows={sortAssetGridRows} assignRowValues={assignRowValues}/>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'liabilities'} onChange={handleChange('liabilities')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>Liability summary</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Total for period: R{liabilityTotal}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="overview-assets-grid-container">
                            <DataGrid gridRows={liabilityRows} gridHeaders={liabilityHeaders} saveGridChanges={saveGridChanges}
                                getCellValue={getLiabilityGridRowCellValue} canCommitRow={canCommitRow} useGridOperations={false}
                                deleteRows={deleteGridRows} sortGridRows={sortGridRows} assignRowValues={assignRowValues}/>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="overview-header">
                <CustomDatePicker label="Start date" value={startDate} setValue={setStartDate}/>
                <CustomDatePicker label="End date" value={endDate} setValue={setEndDate}/>
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Calculate" 
                        onClick={calculateClicked} color="primary"/>
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Income Statement" 
                        onClick={incomeStatementClicked} color="primary" disabled={!calculatedData}/>
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Balance Sheet" 
                        onClick={balanceSheetClicked} color="primary" disabled={!calculatedData}/>
            </div>
            
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            <ErrorDialog open={noDates} handleClose={setNoDates} title="Error">
                Please ensure both Start date and End date have been set.
            </ErrorDialog>
            <ErrorDialog open={badDates} handleClose={setBadDates} title="Error">
                Invalid dates selected. Start date cannot be after the end date.
            </ErrorDialog>
            {loading && <LoadingOverlay />}
        </div>
    );
}

export default Overview;