import "./Stock.css";
import DataGrid from "../DataGrid/DataGrid";
import LoadingOverlay from "../../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import { useEffect, useState } from "react";
import ErrorDialog from "../../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import { validDecimalNumber, validateIntegerNumber } from "../../../Shared/Utils/number-utils";
import SimpleDropdown from "../../../Shared/Components/Dropdowns/SimpleDropdown";
import { MONTHS, convertDateObjectToString, convertDateStringToDateObject, formatCurrentDateObjectToString, getMonthNumber, validateDateString } from "../../../Shared/Utils/date-utils";
import { MenuItem } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import SummaryView from "../SummaryView/SummaryView";
import SummaryItem from "../SummaryView/SummaryItem";
import userContextServiceInstance from "../../../Shared/Services/user-context-service";
import stockServiceInstance from "../../../Shared/Services/BusinessManager/stock-service";
import stockTypeServiceInstance from "../../../Shared/Services/BusinessManager/stock-type-service";
import summaryServiceInstance from "../../../Shared/Services/BusinessManager/summary-service";
import { SUMMARY_TYPES } from "../../../Shared/Enums/summary-types-enum";

const Stock = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [defaultMonth, setDefaultMonth] = useState("");
    const [defaultYear, setDefaultYear] = useState(0);
    const [defaultYearCopy, setDefaultYearCopy] = useState(0);
    const [summaryDetails, setSummaryDetails] = useState({});
    const [stockTypes, setStockTypes] = useState([]);

    useEffect(() => {
        const getIncomeData = async () => {
            const businessEntityId = userContextServiceInstance.getBusinessEntityID();
            var response = await stockTypeServiceInstance.getItems(businessEntityId);
            if(response.status !== 200){
                setError(true);
                setLoading(false);
                return;
            }

            setStockTypes(response.data);

            const gridHeaders = [
                {id: 1, value: "StockType", displayValue: "Inventory type", cellWidth: 150, validCellValue: validStockType, isEditable: true, isDropdownCell: true}, 
                {id: 2, value: "Description", displayValue: "Description", cellWidth: 180, validCellValue: validDescription, isEditable: true, isDropdownCell: true}, 
                {id: 3, value: "Date", displayValue: "Date", cellWidth: 120, validCellValue: validDate, isEditable: true},
                {id: 4, value: "Amount", displayValue: "Amount (R)", cellWidth: 150, validCellValue: validAmount, isEditable: true},
                {id: 5, value: "Quantity", displayValue: "Quantity", cellWidth: 100, validCellValue: validQuantity, isEditable: true},
                {id: 6, value: "Total", displayValue: "Total (R)", cellWidth: 150, validCellValue: null, isEditable: false},
                {id: 7, value: "Comment", displayValue: "Comment", cellWidth: 400, validCellValue: validComment, isEditable: true}
            ];
    
            //set current year and month
            const date = new Date();
            setDefaultYear(date.getFullYear());
            setDefaultYearCopy(date.getFullYear());
            setDefaultMonth(MONTHS[date.getMonth()]);

            setHeaders(gridHeaders);
            
            await getGridRowDataFromApi(date.getFullYear(), MONTHS[date.getMonth()]);
            await getSummaryFromApi(date.getFullYear());

            setLoading(false);
        }
        
        getIncomeData().catch(err => {
            console.error(err);
            setLoading(false);
            setError(true);
        })

    }, []);

    const getDropdownCellOptions = (headerValue, row) => {
        var options = [];
        switch(headerValue){
            case "StockType":
                stockTypes.forEach(item => {
                    options.push({id: item.entityID, value: item.name, text: item.name});
                });

                return options;
            
            case "Description":
                var selectedStockType = row.rowData["StockType"];
                if(selectedStockType === ""){
                    return [];
                }

                var stockTypeItems = [];
                stockTypes.forEach(item => {
                    if(item.name === selectedStockType){
                        stockTypeItems = item.items;
                    }
                });
                
                stockTypeItems.forEach(item => {
                    options.push({id: item.entityID, value: item.name, text: item.name});
                });

                return options;

            default:
                return [];
        }
    }

    const getGridRowDataFromApi = async (year, month) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        const monthNumber = getMonthNumber(month);

        var response = await stockServiceInstance.getItems(businessEntityId, year, monthNumber);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        items.forEach(item => {
            let date = convertDateObjectToString(item.purchaseDate);
            gridRows.push(
                {
                    id: item.entityID, 
                    rowData: { StockType: item.stockType, Description: item.description, Amount: item.amount, Date: date, Quantity: item.quantity, Comment: item.comment }, 
                    hasError: false,
                    rowSelected: false,
                    isEmpty: false,
                    hasChanges: false,
                    isComplete: true,
                    isNewRow: false
                }
            );
        });

        gridRows = sortGridRows(gridRows);
        setRows(gridRows);
    }

    const getSummaryFromApi = async (year) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        var response = await summaryServiceInstance.getSummary(businessEntityId, year, SUMMARY_TYPES.Stocks);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let summary = response.data;
        setSummaryDetails(summary.values);
    }

    const sortGridRows = (rows) => {
        rows.sort((a, b) => {
            let date1 = convertDateStringToDateObject(a.rowData["Date"]).getTime();
            let date2 = convertDateStringToDateObject(b.rowData["Date"]).getTime();
            return date1 - date2;
        });

        return rows;
    }

    const getGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            case "Total":
                if(row.rowData["Amount"] === "" || row.rowData["Quantity"] === "" || !validAmount(row.rowData["Amount"]) || !validQuantity(row.rowData["Quantity"])){
                    return "0";
                }
                let amount =  parseFloat(row.rowData["Amount"]);
                let quantity =  parseInt(row.rowData["Quantity"]);
                let total = amount * quantity;
                return Math.round((total + Number.EPSILON) * 100) / 100;
            
            default:
                return row.rowData[headerValue];
        }
    }

    const assignRowValues = (row, header, newValue) => {
        row.rowData[header.value] = newValue;
        if(header.value === "StockType"){
            row.rowData["Description"] = "";
        }

        if(header.value !== "Date" && row.rowData["Date"] === ""){
            row.rowData["Date"] = formatCurrentDateObjectToString();
        }

        return row;
    }

    const getEmptyRow = () => {
        return { 
            id: uuidv4(), 
            rowData: {StockType: "", Description: "", Amount: "", Date: "", Quantity: "", Comment: ""}, 
            hasError: false, 
            rowSelected: false, 
            isEmpty: true,
            hasChanges: false,
            isComplete: true,
            isNewRow: true
        };
    }

    const canCommitRow = (row) => {
        return row.rowData["StockType"] !== "" && row.rowData["Description"] !== "" && row.rowData["Amount"] !== "" && row.rowData["Date"] !== "" && row.rowData["Quantity"] !== "";
    }

    const validDescription = (value) => {
        return value !== null && value !== undefined;
    }

    const validAmount = (value) => {
        if(value === ""){
            return true;
        }

        return validDecimalNumber(value);
    }

    const validComment = (value) => {
        return value !== null && value !== undefined;
    }

    const validDate = (value) => {
        if(value === ""){
            return true;
        }

        return validateDateString(value);
    }

    const validQuantity = (value) => {
        if(value === ""){
            return true;
        }
        return validateIntegerNumber(value);
    }

    const validStockType = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const saveStockChanges = (row) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            EntityID: row.id,
            BusinessEntityID: businessEntityId,
            StockType: row.rowData["StockType"],
            Description: row.rowData["Description"],
            PurchaseDate: convertDateStringToDateObject(row.rowData["Date"]),
            Amount: parseFloat(row.rowData["Amount"]),
            Quantity: parseInt(row.rowData["Quantity"]),
            Comment: row.rowData["Comment"]
        };

        setLoading(true);

        if(row.isNewRow){
            stockServiceInstance.addItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
        else {
            stockServiceInstance.updateItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
    }

    const deleteRows = async (guidList) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            BusinessEntityID: businessEntityId,
            StockEntityIDs: guidList
        };

        setLoading(true);
        var response = await stockServiceInstance.removeItems(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        setLoading(false);
        await getGridRowDataFromApi(defaultYear, defaultMonth);
    }

    const handleMonthChanged = async (event) => {
        setLoading(true);
        setDefaultMonth(event.target.value);
        getGridRowDataFromApi(defaultYear, event.target.value).then(() => {
            setLoading(false);
        });
    }

    const handleYearChanged = async (event) => {
        setLoading(true);
        setDefaultYear(event.target.value);
        getGridRowDataFromApi(event.target.value, defaultMonth).then(() => {
            setLoading(false);
        });
    }

    const getGridRowFilters = () => {
        let monthItems = [];
        let yearItems = [];

        MONTHS.forEach((month, index) => {
            monthItems.push(<MenuItem key={index} value={month}>{month}</MenuItem>);
        });

        for(let i = defaultYearCopy;  i< defaultYearCopy + 50; i++){
            yearItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
        }

        return (
            <div className="income-filters">
                <SimpleDropdown label="Month" defaultValue={defaultMonth} handleChange={handleMonthChanged} size="small">
                    {monthItems}
                </SimpleDropdown>
                <SimpleDropdown label="Year" defaultValue={defaultYear} handleChange={handleYearChanged} size="small">
                    {yearItems}
                </SimpleDropdown>
            </div>
        );
    }

    const refreshSummaryView = async () => {
        // make api request to get new data
        setLoading(true);
        await getSummaryFromApi(defaultYear);
        setLoading(false);
    }

    return (
        <div className="stock-container">
            <div className="stock-grid-container">
                <DataGrid gridRows={rows} gridHeaders={headers} saveGridChanges={saveStockChanges} getEmptyRow={getEmptyRow}
                    getGridFilters={getGridRowFilters} getCellValue={getGridRowCellValue} canCommitRow={canCommitRow}
                    deleteRows={deleteRows} sortGridRows={sortGridRows} assignRowValues={assignRowValues} 
                    getDropdownCellOptions={getDropdownCellOptions}/>
            </div>
            <div className="stock-summary-container">
                {summaryDetails && <SummaryView title={"Total stock for " + defaultYear} refreshSummaryData={refreshSummaryView}>
                    {Object.keys(summaryDetails).map((key, index) => (
                        <SummaryItem key={key} label={key} value={"R" + summaryDetails[key]}/>    
                    ))}
                </SummaryView>}
            </div>
            
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            {loading && <LoadingOverlay />}
        </div>
    )
}

export default Stock;