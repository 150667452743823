import "./OtherIncome.css";
import { useEffect, useState } from "react";
import ErrorDialog from "../../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import LoadingOverlay from "../../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import SummaryItem from "../SummaryView/SummaryItem";
import SummaryView from "../SummaryView/SummaryView";
import DataGrid from "../DataGrid/DataGrid";
import userContextServiceInstance from "../../../Shared/Services/user-context-service";
import incomeTypeServiceInstance from "../../../Shared/Services/BusinessManager/income-type-service";
import { convertDateObjectToString, convertDateStringToDateObject, formatCurrentDateObjectToString, getMonthNumber, MONTHS, validateDateString } from "../../../Shared/Utils/date-utils";
import otherIncomeServiceInstance from "../../../Shared/Services/BusinessManager/other-income-service";
import summaryServiceInstance from "../../../Shared/Services/BusinessManager/summary-service";
import { SUMMARY_TYPES } from "../../../Shared/Enums/summary-types-enum";
import { v4 as uuidv4 } from 'uuid';
import { validDecimalNumber } from "../../../Shared/Utils/number-utils";
import { MenuItem } from "@mui/material";
import SimpleDropdown from "../../../Shared/Components/Dropdowns/SimpleDropdown";

const OtherIncome = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [defaultMonth, setDefaultMonth] = useState("");
    const [defaultYear, setDefaultYear] = useState(0);
    const [defaultYearCopy, setDefaultYearCopy] = useState(0);
    const [incomeTypes, setIncomeTypes] = useState([]);
    const [summaryDetails, setSummaryDetails] = useState({});

    useEffect(() => {
        const getIncomeData = async () => {
            const businessEntityId = userContextServiceInstance.getBusinessEntityID();    
            let response = await incomeTypeServiceInstance.getItems(businessEntityId);
            if(response.status !== 200){
                setError(true);
                setLoading(false);
                return;
            }
            
            setIncomeTypes(response.data);

            const gridHeaders = [
                {id: 1, value: "IncomeType", displayValue: "Income type", cellWidth: 150, validCellValue: validIncomeType , isEditable: true, isDropdownCell: true},
                {id: 2, value: "Description", displayValue: "Description", cellWidth: 180, validCellValue: validDescription, isEditable: true, isDropdownCell: true}, 
                {id: 3, value: "Date", displayValue: "Date", cellWidth: 120, validCellValue: validDate, isEditable: true},
                {id: 4, value: "Amount", displayValue: "Amount (R)", cellWidth: 150, validCellValue: validAmount, isEditable: true},
                {id: 5, value: "Comment", displayValue: "Comment", cellWidth: 400, validCellValue: validComment, isEditable: true}
            ];
    
            //set current year and month
            const date = new Date();
            setDefaultYear(date.getFullYear());
            setDefaultYearCopy(date.getFullYear());
            setDefaultMonth(MONTHS[date.getMonth()]);

            setHeaders(gridHeaders);

            await getGridRowDataFromApi(date.getFullYear(), MONTHS[date.getMonth()]);
            await getSummaryFromApi(date.getFullYear());

            setLoading(false);
        }
        
        getIncomeData().catch(err => {
            console.error(err);
            setLoading(false);
            setError(true);
        });

    }, []);

    const getDropdownCellOptions = (headerValue, row) => {
        var options = [];
        switch(headerValue){
            case "IncomeType":
                incomeTypes.forEach(item => {
                    options.push({id: item.entityID, value: item.name, text: item.name});
                });

                return options;
            
            case "Description":
                var selectedIncomeType = row.rowData["IncomeType"];
                if(selectedIncomeType === ""){
                    return [];
                }

                var incomeTypeItems = [];
                incomeTypes.forEach(item => {
                    if(item.name === selectedIncomeType){
                        incomeTypeItems = item.items;
                    }
                });
                
                incomeTypeItems.forEach(item => {
                    options.push({id: item.entityId, value: item.name, text: item.name});
                });

                return options;

            default:
                return [];
        }
    }

    const getGridRowDataFromApi = async (year, month) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        const monthNumber = getMonthNumber(month);

        var response = await otherIncomeServiceInstance.getItems(businessEntityId, year, monthNumber);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        items.forEach(item => {
            let date = convertDateObjectToString(item.incomeDate);
            gridRows.push(
                {
                    id: item.entityID, 
                    rowData: {IncomeType: item.incomeType, Description: item.description, Amount: item.amount, Date: date, Comment: item.comment }, 
                    hasError: false,
                    rowSelected: false,
                    isEmpty: false,
                    hasChanges: false,
                    isComplete: true,
                    isNewRow: false
                }
            );
        });

        gridRows = sortGridRows(gridRows);
        setRows(gridRows);
    }

    const getSummaryFromApi = async (year) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        var response = await summaryServiceInstance.getSummary(businessEntityId, year, SUMMARY_TYPES.OtherIncomes);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let summary = response.data;
        setSummaryDetails(summary.values);
    }

    const sortGridRows = (rows) => {
        rows.sort((a, b) => {
            let date1 = convertDateStringToDateObject(a.rowData["Date"]).getTime();
            let date2 = convertDateStringToDateObject(b.rowData["Date"]).getTime();
            return date1 - date2;
        });

        return rows;
    }

    const getGridRowCellValue = (headerValue, row) => {
        return row.rowData[headerValue];
    }

    const assignRowValues = (row, header, newValue) => {
        row.rowData[header.value] = newValue;
        if(header.value === "IncomeType"){
            row.rowData["Description"] = "";
        }
        
        if(header.value !== "Date" && row.rowData["Date"] === ""){
            row.rowData["Date"] = formatCurrentDateObjectToString();
        }

        return row;
    }

    const getEmptyRow = () => {
        return { 
            id: uuidv4(), 
            rowData: {IncomeType: "", Description: "", Amount: "", Date: "", Comment: ""}, 
            hasError: false, 
            rowSelected: false, 
            isEmpty: true,
            hasChanges: false,
            isComplete: true,
            isNewRow: true
        };
    }

    const canCommitRow = (row) => {
        return row.rowData["IncomeType"] !== "" && row.rowData["Description"] !== "" && row.rowData["Amount"] !== "" && row.rowData["Date"] !== "";
    }


    const validDescription = (value) => {
        return value !== null && value !== undefined;
    }

    const validAmount = (value) => {
        if(value === ""){
            return true;
        }

        return validDecimalNumber(value);
    }

    const validComment = (value) => {
        return value !== null && value !== undefined;
    }

    const validDate = (value) => {
        if(value === ""){
            return true;
        }

        return validateDateString(value);
    }

    const validIncomeType = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const saveIncomeChanges = (row) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            EntityID: row.id,
            BusinessEntityID: businessEntityId,
            IncomeType: row.rowData["IncomeType"],
            Description: row.rowData["Description"],
            IncomeDate: convertDateStringToDateObject(row.rowData["Date"]),
            Amount: parseFloat(row.rowData["Amount"]),
            Comment: row.rowData["Comment"]
        };

        setLoading(true);

        if(row.isNewRow){
            otherIncomeServiceInstance.addItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
        else {
            otherIncomeServiceInstance.updateItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
    }

    const deleteRows = async (guidList) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            BusinessEntityID: businessEntityId,
            IncomeEntityIDs: guidList
        };

        setLoading(true);
        var response = await otherIncomeServiceInstance.removeItems(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        setLoading(false);
        await getGridRowDataFromApi(defaultYear, defaultMonth);
    }

    const handleMonthChanged = async (event) => {
        setLoading(true);
        setDefaultMonth(event.target.value);
        getGridRowDataFromApi(defaultYear, event.target.value).then(() => {
            setLoading(false);
        });
    }

    const handleYearChanged = async (event) => {
        setLoading(true);
        setDefaultYear(event.target.value);
        getGridRowDataFromApi(event.target.value, defaultMonth).then(() => {
            setLoading(false);
        });
    }

    const getGridRowFilters = () => {
        let monthItems = [];
        let yearItems = [];

        MONTHS.forEach((month, index) => {
            monthItems.push(<MenuItem key={index} value={month}>{month}</MenuItem>);
        });

        for(let i = defaultYearCopy;  i< defaultYearCopy + 50; i++){
            yearItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
        }

        return (
            <div className="other-income-filters">
                <SimpleDropdown label="Month" defaultValue={defaultMonth} handleChange={handleMonthChanged} size="small">
                    {monthItems}
                </SimpleDropdown>
                <SimpleDropdown label="Year" defaultValue={defaultYear} handleChange={handleYearChanged} size="small">
                    {yearItems}
                </SimpleDropdown>
            </div>
        );
    }

    const refreshSummaryView = async () => {
        // make api request to get new data
        setLoading(true);
        await getSummaryFromApi(defaultYear);
        setLoading(false);
    }

    return (
        <div className="other-income-container">
            <div className="other-income-grid-container">
                {<DataGrid gridRows={rows} gridHeaders={headers} saveGridChanges={saveIncomeChanges} getEmptyRow={getEmptyRow}
                    getGridFilters={getGridRowFilters} getCellValue={getGridRowCellValue} canCommitRow={canCommitRow} 
                    deleteRows={deleteRows} sortGridRows={sortGridRows} assignRowValues={assignRowValues} 
                    getDropdownCellOptions={getDropdownCellOptions}/>}
            </div>
            <div className="other-income-summary-container">
                {summaryDetails && <SummaryView title={"Total other income for " + defaultYear} refreshSummaryData={refreshSummaryView}>
                    {Object.keys(summaryDetails).map((key, index) => (
                        <SummaryItem key={key} label={key} value={"R" + summaryDetails[key]}/>    
                    ))}
                </SummaryView>}
            </div>
            
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            {loading && <LoadingOverlay />}
        </div>
    )
}

export default OtherIncome;