import "./Define.css";

const DefineOption = (props) => {
    const classNames = props.isActive ? "define-option define-option-active" : "define-option"; 

    return (
        <div className={classNames} onClick={props.onClick}>
            {props.children}
            <div className="define-option-text">{props.title}</div>
        </div>
    )
}

export default DefineOption;