
const BuildEnvHostNames = Object.freeze({
    Localhost: "localhost",
    Dev: "",
    QA: "",
    Production: "trans-tech-solutions.co.za"
});

const hostname = window && window.location && window.location.hostname;

// api endpoints
let apiHost;

//feature flags
let wellnessActivitiesEnabled = false;

if (hostname === BuildEnvHostNames.Dev){
    apiHost = "";
} else if (hostname === BuildEnvHostNames.QA){
    apiHost = "";
} else if (hostname === BuildEnvHostNames.Production){
    apiHost = "https://api.trans-tech-solutions.co.za";
} else if (hostname === BuildEnvHostNames.Localhost){
    apiHost = "http://localhost:5000";
}

// api endpoints
export const API_HOST = apiHost;

// feature flags
export const WELLNESS_ACTIVITIES_ENABLED = wellnessActivitiesEnabled;