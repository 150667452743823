import { HTTP_VERB } from "../../Enums/http-enums";
import HttpClient from "../HttpClient/http-client";
import { AxiosRequestConfig } from "axios";
import { API_HOST } from "../../api-config";

const baseUrl = API_HOST + "/api/business-manager/";

class UserSettingService {
    static instance: UserSettingService;
    client: typeof HttpClient;

    constructor(client: typeof HttpClient){
        if(!UserSettingService.instance){
            this.client = client;
            UserSettingService.instance = this;
            return UserSettingService.instance;
        }

        this.client = UserSettingService.instance.client;
        return UserSettingService.instance;
    }

    async getUserSettings(){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.GET,
            baseUrl: baseUrl,
            url: baseUrl + "UserSetting/GetUserSettings",
            headers: {
                "Content-Type": "application/json"
            },
            data: null
        }

        return (await this.client.request(config));
    }

    async updateAssetSettings(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.PUT,
            baseUrl: baseUrl,
            url: baseUrl + "UserSetting/UpdateAssetSettings",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async updateLiabilitySettings(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.PUT,
            baseUrl: baseUrl,
            url: baseUrl + "UserSetting/UpdateLiabilitySettings",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }
}

const userSettingServiceInstance = new UserSettingService(HttpClient);
Object.freeze(userSettingServiceInstance);

export default userSettingServiceInstance;