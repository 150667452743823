import "./Assets.css";
import DataGrid from "../DataGrid/DataGrid";
import LoadingOverlay from "../../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import { useEffect, useState } from "react";
import ErrorDialog from "../../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import { validDecimalNumber } from "../../../Shared/Utils/number-utils";
import { v4 as uuidv4 } from 'uuid';
import userContextServiceInstance from "../../../Shared/Services/user-context-service";
import assetServiceInstance from "../../../Shared/Services/BusinessManager/asset-service";
import { convertDateObjectToString, convertDateStringToDateObject, formatCurrentDateObjectToString, getMonthDiff, getMonthNumber, MONTHS, validateDateString } from "../../../Shared/Utils/date-utils";
import { calculateCompoundInterestValue, calculateDepreciatedValue, calculateSimpleInterestValue } from "../../../Shared/Utils/formula-utils";
import { MenuItem, Typography } from "@mui/material";
import SimpleDropdown from "../../../Shared/Components/Dropdowns/SimpleDropdown";
import userSettingServiceInstance from "../../../Shared/Services/BusinessManager/user-setting-service";

const Assets = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [defaultMonth, setDefaultMonth] = useState("");
    const [defaultYear, setDefaultYear] = useState(0);
    const [defaultYearCopy, setDefaultYearCopy] = useState(0);
    
    useEffect(() => {
        const getData = async () => {

            const gridHeaders = [
                {id: 1, value: "AssetType", displayValue: "Asset Type", cellWidth: 150, validCellValue: validAssetType, isEditable: true, isDropdownCell: true},
                {id: 2, value: "Description", displayValue: "Asset Description", cellWidth: 200, validCellValue: validDescription, isEditable: true, isDropdownCell: true}, 
                {id: 3, value: "StartingAmount", displayValue: "Principle Value (R)", cellWidth: 150, validCellValue: validAmount, isEditable: true},
                {id: 4, value: "StartDate", displayValue: "Date of purchase", cellWidth: 150, validCellValue: validDate, isEditable: true},
                {id: 5, value: "InterestType", displayValue: "Interest type", cellWidth: 150, validCellValue: validInterestType, isEditable: true, isDropdownCell: true},
                {id: 6, value: "CompoundPeriod", displayValue: "Compounding Period", cellWidth: 200, validCellValue: validCompoundPeriods, isEditable: true, isDropdownCell: true},                
                {id: 7, value: "InterestRate", displayValue: "Interest rate p.a.(%)", cellWidth: 150, validCellValue: validInterestRate, isEditable: true},
                {id: 8, value: "FutureValue", displayValue: "Current Value (R)", cellWidth: 150, validCellValue: null, isEditable: false},
                {id: 9, value: "Comment", displayValue: "Comment", cellWidth: 300, validCellValue: validComment, isEditable: true},
            ];
            
            await getUserSettings();
            setHeaders(gridHeaders);

            setLoading(false);
        }
        
        getData().catch(err => {
            console.error(err);
            setLoading(false);
            setError(true);
        })

    }, []);

    const getUserSettings = async () => {
        var response = await userSettingServiceInstance.getUserSettings();
        const dto = response.data;
        if(dto && dto.lastAssetYear && dto.lastAssetMonth){
            setDefaultYear(dto.lastAssetYear);
            setDefaultYearCopy(dto.lastAssetYear);
            setDefaultMonth(MONTHS[dto.lastAssetMonth-1]);

            await getGridRowDataFromApi(dto.lastAssetYear, MONTHS[dto.lastAssetMonth-1]);
        }
        else {
            //set current year and month
            const date = new Date();
            setDefaultYear(date.getFullYear());
            setDefaultYearCopy(date.getFullYear());
            setDefaultMonth(MONTHS[date.getMonth()]);

            await getGridRowDataFromApi(date.getFullYear(), MONTHS[date.getMonth()]);
        }
    }

    const getDropdownCellOptions = (headerValue, row) => {
        var options = [];

        switch(headerValue){
            case "AssetType":
                return [
                    {id: "AT0", value: "Current Asset", text: "Current Asset"},
                    {id: "AT1", value: "Fixed Asset", text: "Fixed Asset"},
                    {id: "AT2", value: "Intangible Asset", text: "Intangible Asset"},
                ];
            
            case "Description":
                if(row.rowData["AssetType"] === "Current Asset"){
                    return [
                        {id: "D01", value: "Accounts receivable", text: "Accounts receivable"},
                        {id: "D02", value: "Cash and cash equivalents", text: "Cash and cash equivalents"},
                        {id: "D03", value: "Other receivables", text: "Other receivables"},
                        {id: "D04", value: "Short term investments", text: "Short term investments"},
                        {id: "D05", value: "Supplies", text: "Supplies"},
                    ];

                } else if(row.rowData["AssetType"] === "Fixed Asset"){
                    return [
                        {id: "D01", value: "Buildings", text: "Buildings"},
                        {id: "D02", value: "Equipment", text: "Equipment"},
                        {id: "D03", value: "Land", text: "Land"},
                        {id: "D04", value: "Land improvements", text: "Land improvements"},
                        {id: "D05", value: "Other", text: "Other"},
                        {id: "D06", value: "Supplies", text: "Supplies"},
                        {id: "D07", value: "Vehicles", text: "Vehicles"},
                    ];

                } else if(row.rowData["AssetType"] === "Intangible Asset"){
                    return [
                        {id: "D01", value: "Copyrights", text: "Copyrights"},
                        {id: "D02", value: "Franchises", text: "Franchises"},
                        {id: "D03", value: "Goodwill", text: "Goodwill"},
                        {id: "D04", value: "Government grants", text: "Government grants"},
                        {id: "D05", value: "Other", text: "Other"},
                        {id: "D06", value: "Patents", text: "Patents"},
                        {id: "D07", value: "Trade secrets", text: "Trade secrets"},
                    ];
                } else{
                    return [];
                }

            case "InterestType":
                return [
                    {id: "IT0", value: "Simple", text: "Simple"},
                    {id: "IT1", value: "Compound", text: "Compound"},
                    {id: "IT2", value: "Depreciation", text: "Depreciation"},
                    {id: "IT3", value: "None", text: "None"},
                ];
            
            case "CompoundPeriod":
                return [
                    {id: "CP0", value: "365", text: "Daily (365)"},
                    {id: "CP2", value: "360", text: "Daily (360)"},
                    {id: "CP3", value: "52", text: "Weekly"},
                    {id: "CP4", value: "26", text: "Biweekly"},
                    {id: "CP5", value: "24", text: "Semimonthly"},
                    {id: "CP6", value: "12", text: "Monthly"},
                    {id: "CP7", value: "4", text: "Quarterly"},
                    {id: "CP8", value: "2", text: "Semiannually"},
                    {id: "CP9", value: "1", text: "Annualy"},
                    {id: "CP10", value: "N/A", text: "N/A", hidden: true},
                ];

            default:
                return options;
        }
    }

    const getGridRowDataFromApi = async (year, month) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        const monthNumber = getMonthNumber(month);

        var response = await assetServiceInstance.getItems(businessEntityId, year, monthNumber);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        items.forEach(item => {
            let date = convertDateObjectToString(item.startDate);
            gridRows.push(
                {
                    id: item.entityID,
                    rowData: { AssetType: item.assetType, Description: item.description, StartingAmount: item.startingAmount, StartDate: date, InterestType: item.interestType, CompoundPeriod: item.compoundPeriod, InterestRate: item.interestRate, Comment: item.comment }, 
                    hasError: false,
                    rowSelected: false,
                    isEmpty: false,
                    hasChanges: false,
                    isComplete: true,
                    isNewRow: false
                }
            );
        });

        gridRows = sortGridRows(gridRows);
        setRows(gridRows);
    }

    const sortGridRows = (rows) => {
        rows.sort((a, b) => {
            let description1 = a.rowData["AssetType"];
            let description2 = b.rowData["AssetType"];
            if(description1 === "" || description2 === "") { return 1; }
            return description1.localeCompare(description2);
        });

        return rows;
    }

    const getGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            case "FutureValue":
                return calculateFutureAssetValue(row);
            default:
                return row.rowData[headerValue];
        }
    }

    const calculateFutureAssetValue = (row) => {
        if(row.rowData["InterestType"] === "" || !validAmount(row.rowData["StartingAmount"]) || 
            !validDate(row.rowData["StartDate"]) || !validInterestRate(row.rowData["InterestRate"]) 
            || row.rowData["InterestRate"] === ""){
            return "";
        }

        if(row.rowData["InterestType"] === "None"){
            return parseFloat(row.rowData["StartingAmount"]);
        }

        var interestType = row.rowData["InterestType"];
        var purchaseValue = parseFloat(row.rowData["StartingAmount"]);
        var startDate = convertDateStringToDateObject(row.rowData["StartDate"]);
        var interestRate = parseFloat(row.rowData["InterestRate"]);
        var currentDate = new Date();
        if(currentDate < startDate){
            return purchaseValue;
        }
        var numberMonths = getMonthDiff(startDate, currentDate);
        
        if(interestType === "Depreciation"){
            return calculateDepreciatedValue(purchaseValue, interestRate, numberMonths);
        }
        else if(interestType === "Simple"){
            return calculateSimpleInterestValue(purchaseValue, interestRate, numberMonths);
        }
        else if(interestType === "Compound"){
            var compoundPeriod = parseFloat(row.rowData["CompoundPeriod"]);
            return calculateCompoundInterestValue(purchaseValue, interestRate, numberMonths, compoundPeriod);
        }

        return "";
    }

    const assignRowValues = (row, header, newValue) => {
        row.rowData[header.value] = newValue;
        if(header.value === "InterestType"){
            if(newValue === "Simple" || newValue === "Depreciation"){
                row.rowData["CompoundPeriod"] = "N/A";
            }else if(newValue === "None"){
                row.rowData["CompoundPeriod"] = "N/A";
                row.rowData["InterestRate"] = "0";

            }else if(newValue === "Compound"){
                row.rowData["CompoundPeriod"] = "";
            }
        }

        if(header.value === "CompoundPeriod"){
            if(row.rowData["InterestType"] === "Simple" || row.rowData["InterestType"] === "Depreciation"){
                row.rowData[header.value] = "N/A";
            }else if(row.rowData["InterestType"] === "None"){
                row.rowData[header.value] = "N/A";
                row.rowData["InterestRate"] = "0";
            }
        }

        if(header.value !== "StartDate" && row.rowData["StartDate"] === ""){
            row.rowData["StartDate"] = formatCurrentDateObjectToString();
        }

        return row;
    }

    const getEmptyRow = () => {
        return { 
            id: uuidv4(), 
            rowData: {AssetType: "", Description: "", StartingAmount: "", StartDate: "", InterestType: "", CompoundPeriod: "", InterestRate: "", Comment: ""}, 
            hasError: false, 
            rowSelected: false, 
            isEmpty: true,
            hasChanges: false,
            isComplete: true,
            isNewRow: true
        };
    }

    const canCommitRow = (row) => {
        return row.rowData["AssetType"] !== "" && row.rowData["Description"] !== "" && row.rowData["StartingAmount"] !== "" 
            && row.rowData["StartDate"] !== "" && row.rowData["InterestType"] !== "" && row.rowData["InterestRate"] !== "" 
            && row.rowData["CompoundPeriod"] !== "";
    }

    const validDescription = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const validAssetType = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const validComment = (value) => {
        return value !== null && value !== undefined;
    }

    const validAmount = (value) => {
        if(value === ""){
            return true;
        }
        
        return validDecimalNumber(value);
    }

    const validInterestRate = (value) => {
        if(value === ""){
            return true;
        }
        
        return validDecimalNumber(value);
    }

    const validDate = (value) => {
        if(value === ""){
            return true;
        }

        return validateDateString(value);
    }

    const validInterestType = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const validCompoundPeriods = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const saveAssetChanges = (row) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            EntityID: row.id,
            BusinessEntityID: businessEntityId,
            AssetType: row.rowData["AssetType"],
            Description: row.rowData["Description"],
            StartingAmount: parseFloat(row.rowData["StartingAmount"]),
            StartDate: convertDateStringToDateObject(row.rowData["StartDate"]),
            InterestType: row.rowData["InterestType"],
            InterestRate: parseFloat(row.rowData["InterestRate"]),
            CompoundPeriod: row.rowData["CompoundPeriod"],
            Comment: row.rowData["Comment"]
        };

        setLoading(true);

        if(row.isNewRow){
            assetServiceInstance.addItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
        else {
            assetServiceInstance.updateItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
    }

    const deleteRows = async (guidList) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            BusinessEntityID: businessEntityId,
            AssetEntityIDs: guidList
        };

        setLoading(true);
        var response = await assetServiceInstance.removeItems(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        setLoading(false);
        await getGridRowDataFromApi(defaultYear, defaultMonth);
    }

    const handleMonthChanged = async (event) => {
        setLoading(true);

        const contextToken = userContextServiceInstance.getContextToken();
        const monthNumber = getMonthNumber(event.target.value);
        const payload = { ContextToken: contextToken, AssetMonth: monthNumber, AssetYear: defaultYear };
        await userSettingServiceInstance.updateAssetSettings(payload);

        setDefaultMonth(event.target.value);
        getGridRowDataFromApi(defaultYear, event.target.value).then(() => {
            setLoading(false);
        });
    }

    const handleYearChanged = async (event) => {
        setLoading(true);
        
        const contextToken = userContextServiceInstance.getContextToken();
        const monthNumber = getMonthNumber(defaultMonth);
        const payload = { ContextToken: contextToken, AssetMonth: monthNumber, AssetYear: event.target.value };
        await userSettingServiceInstance.updateAssetSettings(payload);

        setDefaultYear(event.target.value);
        getGridRowDataFromApi(event.target.value, defaultMonth).then(() => {
            setLoading(false);
        });
    }

    const getGridRowFilters = () => {
        let monthItems = [];
        let yearItems = [];

        MONTHS.forEach((month, index) => {
            monthItems.push(<MenuItem key={index} value={month}>{month}</MenuItem>);
        });

        for(let i = defaultYearCopy - 30;  i< defaultYearCopy + 50; i++){
            yearItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
        }

        return (
            <div className="asset-filters">
                <Typography variant="h6" align="center" paddingTop="10px">Financial year end:</Typography>
                <SimpleDropdown label="Month" defaultValue={defaultMonth} handleChange={handleMonthChanged} size="small">
                    {monthItems}
                </SimpleDropdown>
                <SimpleDropdown label="Year" defaultValue={defaultYear} handleChange={handleYearChanged} size="small">
                    {yearItems}
                </SimpleDropdown>
            </div>
        );
    }

    return (
        <div className="assets-container">
            <div className="assets-grid-container">
                <DataGrid gridRows={rows} gridHeaders={headers} saveGridChanges={saveAssetChanges} getEmptyRow={getEmptyRow}
                    getCellValue={getGridRowCellValue} canCommitRow={canCommitRow} deleteRows={deleteRows} sortGridRows={sortGridRows}
                    assignRowValues={assignRowValues} getDropdownCellOptions={getDropdownCellOptions} getGridFilters={getGridRowFilters}/>
            </div>
            
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            {loading && <LoadingOverlay />}
        </div>
    )
}

export default Assets;