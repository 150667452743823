import { useEffect, useState } from "react";
import "./PurchaseSubscription.css";
import UIProvider from "../../../Shared/Components/Providers/UIProvider/UIProvider";
import { Typography } from "@mui/material";
import BasicTextField from "../../../Shared/Components/TextFields/BasicTextField";
import { textFieldSizes, textFieldVariants } from "../../../Shared/Components/TextFields/TextFieldEnums";
import { stringIsNullOrEmpty } from "../../../Shared/Utils/string-utils";
import BasicButton from "../../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../../Shared/Components/Buttons/ButtonEnums";
import LoadingOverlay from "../../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import ErrorDialog from "../../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import AlertDialog from "../../../Shared/Components/Dialogs/AlertDialog/AlertDialog";
import userContextServiceInstance from "../../../Shared/Services/user-context-service";
import businessServiceInstance from "../../../Shared/Services/business-service";
import PaymentScreen from "../../PaymentScreen/PaymentScreen";

const PurchaseSubscription = (props) => {
    const [showLoading, setShowLoading] = useState(false);
    const [error, setError] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [missingInfoDialog, setMissingInfoDialog] = useState(false);
    const [showBusinessCreate, setShowBusinessCreate] = useState(true);
    const [showPayment, setShowPayment] = useState(false);
    const { subscriptionToPurchase } = props;
    
    useEffect(() => {
        let businessEntityId = userContextServiceInstance.getBusinessEntityID();
        if(businessEntityId){
            setShowBusinessCreate(false);
            setShowPayment(true);
            return;
        }
    },[]);

    const validateName = () => {
        return stringIsNullOrEmpty(name);
    }

    const validateDescription = () => {
        return stringIsNullOrEmpty(description);
    }

    const createBusinessClicked = async () => {
        if(validateName() || validateDescription())
        {
            setMissingInfoDialog(true);
            return;
        }

        let contextToken = userContextServiceInstance.getContextToken();
        const businessPayload = { ContextToken: contextToken, Name: name, Description: description };
        setShowLoading(true);
        businessServiceInstance.addItem(businessPayload).then(async response => {
            if(response.status !== 200){
                setError(true);
                setShowLoading(false);
                return;
            }

            userContextServiceInstance.setBusinessEntityID(response.data);
            setShowLoading(false);
            setShowBusinessCreate(false);
            setShowPayment(true);
        });
    }

    const postPaymentSteps = () => {
        setShowLoading(false);
        setShowPayment(false);
        props.setShowPurchaseSubscription(false);
        props.setShowBusinessManager(true);
        props.setBusinessName(name);
        userContextServiceInstance.setBusinessName(name);
    }

    return (
        <div className="bm-purchase-sub-container">
            <UIProvider>
                {
                    showBusinessCreate && 
                    <>
                        <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">Business Details</Typography>
                        <BasicTextField id="name" variant={textFieldVariants.outlined} label="Business Name" size={textFieldSizes.small} value={name} 
                            onChange={(event) => { setName(event.target.value) }} validateText={validateName}/>
                        <BasicTextField id="description" variant={textFieldVariants.outlined} label="Description" size={textFieldSizes.small} value={description} 
                            onChange={(event) => { setDescription(event.target.value) }} validateText={validateDescription}/>
                        <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Create business" 
                                onClick={createBusinessClicked} color="primary"/>
                    </>
                }
                {
                    showPayment && <PaymentScreen subscriptionToPurchase={subscriptionToPurchase} 
                                        postPaymentSteps={postPaymentSteps}/>
                }
            </UIProvider>
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            <AlertDialog open={missingInfoDialog} handleClose={setMissingInfoDialog} title="Missing Information">
                Please complete all required fields.  
            </AlertDialog>
            { showLoading && <LoadingOverlay />}
        </div>
    );
}

export default PurchaseSubscription;