import { useNavigate } from "react-router-dom";
import "./LandingPage.css";
import { useMediaQuery } from 'react-responsive';
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../Shared/Components/Buttons/ButtonEnums";
import Section2Background from "./image2.jpg";
import { Facebook, Instagram, WhatsApp, YouTube } from "@mui/icons-material";
import { WELLNESS_ACTIVITIES_ENABLED } from "../../Shared/api-config";

const LandingPage = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const navigate = useNavigate();
    const headingStyleClass = isMobile ? "section-header-small" : "section-header-large";
    const containerStyleClasses = isMobile ? "landing-page-container-small" : "landing-page-container-large";
    const blankSectionStyleClass = isMobile ? "blank-section-small" : "blank-section-large";
    const section2ImageWidth = isMobile ? "200" : "400";
    const section2ImageHeight = isMobile ? "150" : "200";

    const navigateToRoute = (routeName) => {
        navigate(routeName);
    }

    const learnMoreClicked = () => {
        navigateToRoute("/business-manager");
    }

    const wellnessActivitieslearnMoreClicked = () => {
        navigateToRoute("/wellness-activities");
    }

    const socialMediaLinkClicked = (linkName) => {
        switch(linkName){
            case "instagram":
                console.log("Open instagram");
                break;
            case "youtube":
                console.log("Open youtube");
                break;
            case "whatsapp":
                console.log("Open whatsapp");
                break;
            case "facebook":
                console.log("Open facebook");
                break;
            default:
                console.log("No matching link");
        }
    }

    return (
        <div className={containerStyleClasses}>
            <div className="landing-page-content">

                <div className="section upper-section">
                    
                    <div className={headingStyleClass}>
                        Transforming Businesses Today
                    </div>
                    <div className="section1-subsection">
                        <p>Using technology to optimize and improve your business.</p>
                        <p>
                            Optimizing business record storage:<br/>
                            <ul>
                                <li>No more paper records.</li>
                                <li>Know your business performance at all times.
                                    <ul>
                                        <li>Instantly view your sales, expenses and cost of sales for the financial period.</li>
                                        <li>No more sitting with calculators to determine your business performance.</li>
                                        <li>View your business records from anywhere at anytime.</li>
                                        <li>Instantly get a summary of your records over any date period.</li>
                                    </ul>
                                </li>
                                <li>Generate Income Statments and Balance Sheets for the financial year or any other period of your choice.</li>
                            </ul>
                        </p> 
                    </div>
                </div>
                
                <div className="section2">
                    <div className="business-manager-section">
                        <div className={headingStyleClass}>
                            Business Manager
                        </div>
                        <div className="section2-subsection">
                            <p>Introducing The Business Manager.</p>
                            <p>Your place to manage all of your business admin.</p>
                        </div>
                        <div className="learn-more-btn-container">
                            <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Learn More" 
                                onClick={learnMoreClicked} color="primary"/>
                        </div>
                    </div>
                    <div className="section2-image-container">
                        <img src={Section2Background} width={section2ImageWidth} height={section2ImageHeight}/>
                    </div>
                </div>

                {WELLNESS_ACTIVITIES_ENABLED && 
                    <div className="section3">
                        <div className="business-manager-section">
                            <div className={headingStyleClass}>
                                Prioritize your mental health
                            </div>
                            <div className="section3-subsection">
                                <p>Introducing our Wellness Activities</p>
                                <p>Take some time to destress from the challenges of life.</p>
                                <p>Take part in our Wellness Activities with your family and friends.</p>
                            </div>
                            <div className="learn-more-btn-container">
                                <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Learn More" 
                                    onClick={wellnessActivitieslearnMoreClicked} color="primary"/>
                            </div>
                        </div>
                    </div>
                }
                
                <div className={blankSectionStyleClass}/>
                <div className="section contact-section">
                    <div className={headingStyleClass}>
                        Contact Us
                    </div>
                    <div className="contact-us">
                        <div><strong>Email:</strong> info@trans-tech-solutions.co.za</div>
                        <div><strong>Cellphone:</strong> 062 165 0446</div>
                        <div><strong>Whatsapp:</strong> 062 165 0446</div>
                    </div>
                </div>

                <div className={blankSectionStyleClass}/>
                <hr className="horizontal-line"/>
                <div className="social-media-section">
                    {/* <div className="social-media-button" onClick={() => socialMediaLinkClicked("youtube")}>
                        <YouTube fontSize="large"/>
                    </div> */}
                    <div className="social-media-button">
                        <a href="https://www.instagram.com/trans_tech_solutions/profilecard/?igsh=NWw2dzRxMDhmenU2 " target="_" style={{color: 'inherit'}}>
                            <Instagram fontSize="large"/>
                        </a>
                    </div>
                    <div className="social-media-button" onClick={() => socialMediaLinkClicked("facebook")}>
                        <Facebook fontSize="large"/>
                    </div>
                </div>
                <hr className="horizontal-line"/>
                
                <div className={blankSectionStyleClass}/>
                <hr className="horizontal-line"/>
                <div className="center-section"> 
                    <div className="text-link" onClick={() => navigateToRoute("/terms-and-conditions")}>Terms of Service</div> 
                    and <div className="text-link" onClick={() => navigateToRoute("/privacy-policy")}>Privacy Policy</div>
                </div>
                <hr className="horizontal-line"/>

                <div className="center-section">
                    Tran Tech Solutions Reg. No: 2024/595290/07
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
