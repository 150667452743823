import { useState } from "react";
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../Shared/Components/Buttons/ButtonEnums";
import businessSubscriptionServiceInstance from "../../Shared/Services/business-subscription-service";
import userContextServiceInstance from "../../Shared/Services/user-context-service";
import "./PaymentScreen.css";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import BasicTextField from "../../Shared/Components/TextFields/BasicTextField";
import { textFieldSizes, textFieldVariants } from "../../Shared/Components/TextFields/TextFieldEnums";
import { stringContainsNumbersOnly, stringIsNullOrEmpty } from "../../Shared/Utils/string-utils";
import { MenuItem, Typography } from "@mui/material";
import SimpleDropdown from "../../Shared/Components/Dropdowns/SimpleDropdown";
import paymentServiceInstance from "../../Shared/Services/payment-service";

const PaymentScreen = (props) => {
    const [showLoading, setShowLoading] = useState(false);
    const [error, setError] = useState(false);
    const [missingDetails, setMissingDetails] = useState(false);
    const [bank, setBank] = useState("");
    const [branchCode, setBranchCode] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountType, setAccountType] = useState("");
    const { subscriptionToPurchase } = props;

    const validBranchCode = () => {
        if(branchCode === "") return false;
        return !stringContainsNumbersOnly(branchCode);
    }

    const validAccountNumber = () => {
        if(accountNumber === "") return false;
        return !stringContainsNumbersOnly(accountNumber);
    }
    
    const makePaymentClicked = async () => {
        console.log(subscriptionToPurchase);
        if(stringIsNullOrEmpty(bank) || !stringContainsNumbersOnly(branchCode) || !stringContainsNumbersOnly(accountNumber) 
            || stringIsNullOrEmpty(accountType)){
            setMissingDetails(true);
            return;
        }

        let contextToken = userContextServiceInstance.getContextToken();
        var paymentPayload = {
            ContextToken: contextToken,
            BankName: bank,
            BranchCode: branchCode,
            AccountNumber: accountNumber,
            AccountType: accountType,
            SubscriptionEntityID: subscriptionToPurchase.entityID
        };

        var paymentResponse = await paymentServiceInstance.makePayment(paymentPayload);
        if(paymentResponse.status !== 200){
            setError(true);
            setShowLoading(false);
            return;
        }

        let businessEntityId = userContextServiceInstance.getBusinessEntityID();
        const payload = { ContextToken: contextToken, BusinessEntityID: businessEntityId, SubscriptionEntityID: subscriptionToPurchase.entityID };
        setShowLoading(true);
        businessSubscriptionServiceInstance.addItem(payload).then(response => {
            if(response.status !== 200){
                setError(true);
                setShowLoading(false);
                return;
            }

            props.postPaymentSteps();
        });
    }

    const getBankDropDown = () => {
        return (
            <SimpleDropdown label="Bank Name" defaultValue={bank} handleChange={(event) => { setBank(event.target.value) }} size="small">
                <MenuItem key={1} value="ABSA Bank">ABSA Bank</MenuItem>
                <MenuItem key={2} value="ABSA Bank">Africa Bank</MenuItem>
                <MenuItem key={3} value="Bank Zero">Bank Zero</MenuItem>
                <MenuItem key={4} value="Bidvest Bank Limited">Bidvest Bank</MenuItem>
                <MenuItem key={5} value="Capitec Bank">Capitec Bank</MenuItem>
                <MenuItem key={6} value="Discovery Bank">Discovery Bank</MenuItem>
                <MenuItem key={7} value="First National Bank">First National Bank</MenuItem>
                <MenuItem key={8} value="FirstRand Bank">FirstRand Bank</MenuItem>
                <MenuItem key={9} value="FMB - Rand Merchant Bank">FMB - Rand Merchant Bank</MenuItem>
                <MenuItem key={10} value="Grindrod Bank Limited">Grindrod Bank Limited</MenuItem>
                <MenuItem key={11} value="Imperial Bank South Africa">Imperial Bank South Africa</MenuItem>
                <MenuItem key={12} value="Investec Bank">Investec Bank</MenuItem>
                <MenuItem key={13} value="Ithala Bank">Ithala Bank</MenuItem>
                <MenuItem key={14} value="Mercantile Bank">Mercantile Bank</MenuItem>
                <MenuItem key={15} value="Nedbank">Nedbank</MenuItem>
                <MenuItem key={16} value="Old Mutual Bank">Old Mutual Bank</MenuItem>
                <MenuItem key={17} value="Sasfin Bank">Sasfin Bank</MenuItem>
                <MenuItem key={18} value="Ubank">Ubank</MenuItem>
                <MenuItem key={19} value="Standard Bank">Standard Bank</MenuItem>
                <MenuItem key={20} value="TymeBank">TymeBank</MenuItem>
            </SimpleDropdown>
        );
    }

    const getAccountTypeDropDown = () => {
        return (
            <SimpleDropdown label="Account Type" defaultValue={accountType} handleChange={(event) => { setAccountType(event.target.value) }} size="small">
                <MenuItem key={1} value="Cheque Account">Cheque Account</MenuItem>
                <MenuItem key={2} value="Current Account">Current Account</MenuItem>
                <MenuItem key={3} value="Savings Account">Savings Account</MenuItem>
            </SimpleDropdown>
        );
    }

    return (
        <div className="payments-container">
            <div className="payments-content">
                <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">Bank Details</Typography>
                {getBankDropDown()}
                <div className="banking-number-fields">
                    <BasicTextField id="code" variant={textFieldVariants.outlined} label="Branch Code" size={textFieldSizes.small} 
                        value={branchCode} onChange={(event) => { setBranchCode(event.target.value) }} validateText={validBranchCode}/>
                    <BasicTextField id="accountNumber" variant={textFieldVariants.outlined} label="Account Number" size={textFieldSizes.small} 
                        value={accountNumber} onChange={(event) => { setAccountNumber(event.target.value) }} validateText={validAccountNumber}/>
                </div>
                {getAccountTypeDropDown()}
                
                <br/>

                <div className="discalimer-text">By clicking "make payment" you are authorizing Tran Tech Solutions to debit your account for the next {subscriptionToPurchase.monthlyDuration} month(s).</div>
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Make payment" 
                    onClick={makePaymentClicked} color="primary"/>
            </div>
            <ErrorDialog open={missingDetails} handleClose={setMissingDetails} title="Error">
                Please complete all fields in order to make your purchase.
            </ErrorDialog>
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            { showLoading && <LoadingOverlay />}
        </div>
    );
}

export default PaymentScreen;