import { useEffect, useState } from "react";
import "./Liabilities.css";
import DataGrid from "../DataGrid/DataGrid";
import ErrorDialog from "../../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import LoadingOverlay from "../../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import userSettingServiceInstance from "../../../Shared/Services/BusinessManager/user-setting-service";
import liabilityServiceInstance from "../../../Shared/Services/BusinessManager/liability-service";
import { convertDateObjectToString, convertDateStringToDateObject, formatCurrentDateObjectToString, getMonthNumber, MONTHS, validateDateString } from "../../../Shared/Utils/date-utils";
import userContextServiceInstance from "../../../Shared/Services/user-context-service";
import { v4 as uuidv4 } from 'uuid';
import { validDecimalNumber } from "../../../Shared/Utils/number-utils";
import { MenuItem, Typography } from "@mui/material";
import SimpleDropdown from "../../../Shared/Components/Dropdowns/SimpleDropdown";

const Liabilities = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [defaultMonth, setDefaultMonth] = useState("");
    const [defaultYear, setDefaultYear] = useState(0);
    const [defaultYearCopy, setDefaultYearCopy] = useState(0);

    useEffect(() => {
        const getData = async () => {

            const gridHeaders = [
                {id: 1, value: "LiabilityType", displayValue: "Liability Type", cellWidth: 200, validCellValue: validLiabilityType, isEditable: true, isDropdownCell: true},
                {id: 2, value: "Description", displayValue: "Liability Description", cellWidth: 250, validCellValue: validDescription, isEditable: true, isDropdownCell: true}, 
                {id: 3, value: "Amount", displayValue: "Amount (R)", cellWidth: 150, validCellValue: validAmount, isEditable: true},
                {id: 4, value: "Date", displayValue: "Date", cellWidth: 150, validCellValue: validDate, isEditable: true},
                {id: 5, value: "Comment", displayValue: "Comment", cellWidth: 300, validCellValue: validComment, isEditable: true},
            ];
            
            await getUserSettings();
            setHeaders(gridHeaders);

            setLoading(false);
        }
        
        getData().catch(err => {
            console.error(err);
            setLoading(false);
            setError(true);
        })

    }, []);

    const getUserSettings = async () => {
        var response = await userSettingServiceInstance.getUserSettings();
        const dto = response.data;
        if(dto && dto.lastLiabilityYear && dto.lastLiabilityMonth){
            setDefaultYear(dto.lastLiabilityYear);
            setDefaultYearCopy(dto.lastLiabilityYear);
            setDefaultMonth(MONTHS[dto.lastLiabilityMonth-1]);

            await getGridRowDataFromApi(dto.lastLiabilityYear, MONTHS[dto.lastLiabilityMonth-1]);
        }
        else {
            //set current year and month
            const date = new Date();
            setDefaultYear(date.getFullYear());
            setDefaultYearCopy(date.getFullYear());
            setDefaultMonth(MONTHS[date.getMonth()]);

            await getGridRowDataFromApi(date.getFullYear(), MONTHS[date.getMonth()]);
        }
    }

    const getDropdownCellOptions = (headerValue, row) => {
        var options = [];

        switch(headerValue){
            case "LiabilityType":
                return [
                    {id: "AT0", value: "Current Liability", text: "Current Liability"},
                    {id: "AT1", value: "Long Term Liability", text: "Long Term Liability"},
                ];
            
            case "Description":
                if(row.rowData["LiabilityType"] === "Current Liability"){
                    return [
                        {id: "D01", value: "Accounts payable", text: "Accounts payable"},
                        {id: "D02", value: "Accrued expenses", text: "Accrued expenses"},
                        {id: "D03", value: "Deferred revenue", text: "Deferred revenue"},
                        {id: "D04", value: "Dividends", text: "Dividends"},
                        {id: "D05", value: "Interest", text: "Interest"},
                        {id: "D06", value: "Other current liabilities", text: "Other current liabilities"},
                        {id: "D08", value: "Pre-sold goods and services", text: "Pre-sold goods and services"},
                        {id: "D09", value: "Salaries and wages", text: "Salaries and wages"},
                        {id: "D10", value: "Short-term loans", text: "Short-term loans"},
                        {id: "D11", value: "Taxes", text: "Taxes"},
                    ];

                } else if(row.rowData["LiabilityType"] === "Long Term Liability"){
                    return [
                        {id: "D01", value: "Bonds payable", text: "Bonds payable"},
                        {id: "D03", value: "Deferred revenue", text: "Deferred revenue"},
                        {id: "D04", value: "Deferred tax liabilities", text: "Deferred tax liabilities"},
                        {id: "D05", value: "Long-term leases", text: "Long-term leases"},
                        {id: "D06", value: "Notes payable", text: "Notes payable"},
                        {id: "D07", value: "Other long term liabilities", text: "Other long term liabilities"},
                        {id: "D08", value: "Pension benefit obligations", text: "Pension benefit obligations"},
                        {id: "D09", value: "Product warranties", text: "Product warranties"},
                    ];

                } else {
                    return [];
                }

            default:
                return options;
        }
    }

    const getGridRowDataFromApi = async (year, month) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        const monthNumber = getMonthNumber(month);

        var response = await liabilityServiceInstance.getItems(businessEntityId, year, monthNumber);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        items.forEach(item => {
            let date = convertDateObjectToString(item.date);
            gridRows.push(
                {
                    id: item.entityID,
                    rowData: { LiabilityType: item.liabilityType, Description: item.description, Amount: item.amount, Date: date, Comment: item.comment }, 
                    hasError: false,
                    rowSelected: false,
                    isEmpty: false,
                    hasChanges: false,
                    isComplete: true,
                    isNewRow: false
                }
            );
        });

        gridRows = sortGridRows(gridRows);
        setRows(gridRows);
    }

    const sortGridRows = (rows) => {
        rows.sort((a, b) => {
            let description1 = a.rowData["LiabilityType"];
            let description2 = b.rowData["LiabilityType"];
            if(description1 === "" || description2 === "") { return 1; }
            return description1.localeCompare(description2);
        });

        return rows;
    }

    const getGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            default:
                return row.rowData[headerValue];
        }
    }

    const assignRowValues = (row, header, newValue) => {
        row.rowData[header.value] = newValue;
        
        if(header.value !== "Date" && row.rowData["Date"] === ""){
            row.rowData["Date"] = formatCurrentDateObjectToString();
        }

        return row;
    }

    const getEmptyRow = () => {
        return { 
            id: uuidv4(), 
            rowData: {LiabilityType: "", Description: "", Amount: "", Date: "", Comment: ""}, 
            hasError: false, 
            rowSelected: false, 
            isEmpty: true,
            hasChanges: false,
            isComplete: true,
            isNewRow: true
        };
    }

    const canCommitRow = (row) => {
        return row.rowData["LiabilityType"] !== "" && row.rowData["Description"] !== "" && row.rowData["Amount"] !== "" 
            && row.rowData["Date"] !== "";
    }

    const validDescription = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const validLiabilityType = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const validComment = (value) => {
        return value !== null && value !== undefined;
    }

    const validAmount = (value) => {
        if(value === ""){
            return true;
        }
        
        return validDecimalNumber(value);
    }

    const validDate = (value) => {
        if(value === ""){
            return true;
        }

        return validateDateString(value);
    }

    const saveLiabilityChanges = (row) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            EntityID: row.id,
            BusinessEntityID: businessEntityId,
            LiabilityType: row.rowData["LiabilityType"],
            Description: row.rowData["Description"],
            Amount: parseFloat(row.rowData["Amount"]),
            Date: convertDateStringToDateObject(row.rowData["Date"]),
            Comment: row.rowData["Comment"]
        };

        setLoading(true);

        if(row.isNewRow){
            liabilityServiceInstance.addItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
        else {
            liabilityServiceInstance.updateItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setLoading(false);
                    return;
                }
    
                setLoading(false);
            });
        }
    }

    const deleteRows = async (guidList) => {
        const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        let payload = {
            BusinessEntityID: businessEntityId,
            LiabilityEntityIDs: guidList
        };

        setLoading(true);
        var response = await liabilityServiceInstance.removeItems(payload);
        if(response.status !== 200){
            setError(true);
            setLoading(false);
            return;
        }

        setLoading(false);
        await getGridRowDataFromApi(defaultYear, defaultMonth);
    }

    const handleMonthChanged = async (event) => {
        setLoading(true);

        const contextToken = userContextServiceInstance.getContextToken();
        const monthNumber = getMonthNumber(event.target.value);
        const payload = { ContextToken: contextToken, Month: monthNumber, Year: defaultYear };
        await userSettingServiceInstance.updateLiabilitySettings(payload);

        setDefaultMonth(event.target.value);
        getGridRowDataFromApi(defaultYear, event.target.value).then(() => {
            setLoading(false);
        });
    }

    const handleYearChanged = async (event) => {
        setLoading(true);
        
        const contextToken = userContextServiceInstance.getContextToken();
        const monthNumber = getMonthNumber(defaultMonth);
        const payload = { ContextToken: contextToken, Month: monthNumber, Year: event.target.value };
        await userSettingServiceInstance.updateAssetSettings(payload);

        setDefaultYear(event.target.value);
        getGridRowDataFromApi(event.target.value, defaultMonth).then(() => {
            setLoading(false);
        });
    }

    const getGridRowFilters = () => {
        let monthItems = [];
        let yearItems = [];

        MONTHS.forEach((month, index) => {
            monthItems.push(<MenuItem key={index} value={month}>{month}</MenuItem>);
        });

        for(let i = defaultYearCopy - 30;  i< defaultYearCopy + 50; i++){
            yearItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
        }

        return (
            <div className="liabilities-filters">
                <Typography variant="h6" align="center" paddingTop="10px">Financial year end:</Typography>
                <SimpleDropdown label="Month" defaultValue={defaultMonth} handleChange={handleMonthChanged} size="small">
                    {monthItems}
                </SimpleDropdown>
                <SimpleDropdown label="Year" defaultValue={defaultYear} handleChange={handleYearChanged} size="small">
                    {yearItems}
                </SimpleDropdown>
            </div>
        );
    }

    return (
        <div className="liabilities-container">
            <div className="liabilities-grid-container">
                <DataGrid gridRows={rows} gridHeaders={headers} saveGridChanges={saveLiabilityChanges} getEmptyRow={getEmptyRow}
                    getCellValue={getGridRowCellValue} canCommitRow={canCommitRow} deleteRows={deleteRows} sortGridRows={sortGridRows}
                    assignRowValues={assignRowValues} getDropdownCellOptions={getDropdownCellOptions} getGridFilters={getGridRowFilters}/>
            </div>
            
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            {loading && <LoadingOverlay />}
        </div>
    )
}

export default Liabilities;