import "./Subscriptions.css"
import { buttonSizes, buttonVariants } from "../../../Shared/Components/Buttons/ButtonEnums";
import BasicButton from "../../../Shared/Components/Buttons/BasicButton";
import userContextServiceInstance from "../../../Shared/Services/user-context-service";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AlertDialog from "../../../Shared/Components/Dialogs/AlertDialog/AlertDialog";

const SubscriptionCard = (props) => {
    const navigate = useNavigate();
    const [tempMessage, setTempMessage] = useState(false);
    const { subscription } = props;
    
    const purchaseClicked = () => {
        setTempMessage(true);
        return;

        const contextToken = userContextServiceInstance.getContextToken();
        if(contextToken === null || contextToken === undefined){
            navigate("/login");
            return;
        }

        props.setSupscriptionForPurhase(subscription);
    }

    return (
        <div className="bm-subcard-container">
            <div className="bm-subcard-content">
                <div>{subscription.description}</div>
                <div>R{subscription.price}</div>
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.medium} text="purchase" 
                        onClick={purchaseClicked} color="primary"/>
                <AlertDialog open={tempMessage} handleClose={setTempMessage} title="Send us your subscription request"> 
                    Send us an email at <strong>info@trans-tech-solutions.co.za</strong> with the 
                    subscription you wish to purchase.<br/><br/>
                    We will contact you with everything we need to get you started with the Business Manager.  
                </AlertDialog>
            </div>
        </div>
    );
}

export default SubscriptionCard;