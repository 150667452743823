import { Typography } from "@mui/material";
import "./MyProfile.css";
import { useEffect, useState } from "react";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import BasicTextField from "../../Shared/Components/TextFields/BasicTextField";
import { textFieldSizes, textFieldVariants } from "../../Shared/Components/TextFields/TextFieldEnums";
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../Shared/Components/Buttons/ButtonEnums";
import { stringContainsNumbersOnly, stringIsNullOrEmpty, validateEmailAddress } from "../../Shared/Utils/string-utils";
import AlertDialog from "../../Shared/Components/Dialogs/AlertDialog/AlertDialog";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import UserService from "../../Shared/Services/user-service";
import UIProvider from "../../Shared/Components/Providers/UIProvider/UIProvider";
import userContextServiceInstance from "../../Shared/Services/user-context-service";
import { useMediaQuery } from "react-responsive";
import businessSubscriptionServiceInstance from "../../Shared/Services/business-subscription-service";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../Shared/Components/ReactTable/ReactTable";
import { convertDateObjectToString } from "../../Shared/Utils/date-utils";
import ConfirmationDialog from "../../Shared/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

const MyProfile = () => {
    const [showLoading, setShowLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [idNumber, setIDNumber] = useState("");
    const [cellphone, setCellphone] = useState("");
    const [address, setAddress] = useState("");
    const [error, setError] = useState(false);
    const [missingInfoDialog, setMissingInfoDialog] = useState(false);
    const [profileUpdated, setProfileUpdated] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [columns, setColumns] = useState([]);
    const [confirmCancelSubscription, setConfirmCancelSubscription] = useState(false);
    const [cancelSubscriptionSuccess, setCancelSubscriptionSuccess] = useState(false);
    const [subscriptionToCancel, setSubscriptionToCancel] = useState({});
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const containerStyleClasses = isMobile ? "my-profile-container-small" : "my-profile-container-large";
    const columnHelper = createColumnHelper();

    useEffect(() => {
        const getUserData = async () => {
            var localEmail = userContextServiceInstance.getEmail();
            var response = await UserService.GetDetails(localEmail);
            if(!response || response.status !== 200){
                setError(true);
                setShowLoading(false);
                return;
            }

            var subscriptionsResponse = await businessSubscriptionServiceInstance.getAllForUser();
            if(!subscriptionsResponse || subscriptionsResponse.status !== 200){
                setError(true);
                setShowLoading(false);
                return;
            }
            setSubscriptions(subscriptionsResponse.data);
            generateColumns();

            const userDetails = response.data;
            setFirstName(userDetails.name);
            setLastName(userDetails.surname);
            setEmail(userDetails.email);
            setIDNumber(userDetails.idNumber);
            setCellphone(userDetails.cellphone)
            setAddress(userDetails.address);
            setShowLoading(false);
        }

        setShowLoading(true);
        getUserData().catch(err => {
            console.error(err);
            setShowLoading(false);
            setError(true);
        });

    }, []);

    const generateColumns = () => {
        var items = [
            columnHelper.accessor('businessName', {
                header: () => 'Business Name',
                cell: info => info.renderValue()
            }),
            columnHelper.accessor('subscriptionDescription', {
                header: () => 'Subscription',
                cell: info => info.renderValue()
            }),
            columnHelper.accessor('subscriptionStart', {
                header: () => 'Subscription Start',
                cell: info => formatDate(info.renderValue())
            }),
            columnHelper.accessor('subscriptionEnd', {
                header: () => 'Subscription Expiry',
                cell: info => formatDate(info.renderValue())
            }),
            columnHelper.accessor('subscriptionPrice', {
                header: () => 'Price',
                cell: info => formatPrice(info.renderValue())
            }),
            columnHelper.accessor('isActive', {
                header: () => 'Status',
                cell: info => getStatusValue(info.renderValue())
            }),
            {
                id: 'cancelSubscription',
                cell: ({row}) => getCancelButton(row),
                header: "",
            }
        ];

        setColumns(items);
    }

    const getCancelButton = (row) => {
        return (
            row.original.isActive ?
            <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="cancel subscription" 
                    onClick={() => cancelSubscriptionClicked(row.original)} color="primary"/>
            :
            ""
        );
    }

    const getStatusValue = (value) => {
        const backgroundColour = value ? "active-subscription" : "expired-subscription";
        var displayValue =  value === true ? "Active" : "Expired";

        return (
            <div className={"subscription-status-container " + backgroundColour}>
                {displayValue}
            </div>
        )
    }

    const formatDate = (value) => {
        return convertDateObjectToString(value);
    }

    const formatPrice = (value) => {
        return "R" + value;
    }

    const cancelSubscriptionClicked = (subscriptionDto) => {
        setSubscriptionToCancel(subscriptionDto);
        setConfirmCancelSubscription(true);
    }

    const makeCancelSubscriptionRequest = async () => {
        setShowLoading(true);
        setConfirmCancelSubscription(false);
        
        var contextToken = userContextServiceInstance.getContextToken();
        var payload = {
            ContextToken: contextToken,
            BusinessSubscriptionEntityID: subscriptionToCancel.businessSubscriptionEntityID
        }
        var response = await businessSubscriptionServiceInstance.cancelSubscription(payload);
        if(!response || response.status !== 200){
            setError(true);
            setShowLoading(false);
            return;
        }

        var subscriptionsResponse = await businessSubscriptionServiceInstance.getAllForUser();
        if(!subscriptionsResponse || subscriptionsResponse.status !== 200){
            setError(true);
            setShowLoading(false);
            return;
        }

        setSubscriptions(subscriptionsResponse.data);
        setCancelSubscriptionSuccess(true);
        setShowLoading(false);
    }

    const validateFirstName = () => {
        return stringIsNullOrEmpty(firstName)
    }
    
    const validateLastName = () => {
        return stringIsNullOrEmpty(lastName);
    }

    const validateEmail = () => {
        return stringIsNullOrEmpty(email) || !validateEmailAddress(email);
    }

    const validateIDNumber = () => {
        return stringIsNullOrEmpty(idNumber) || idNumber.length !== 13 || !stringContainsNumbersOnly(idNumber);
    }

    const validateCellphone = () => {
        return stringIsNullOrEmpty(cellphone) || cellphone.length !== 10 || !stringContainsNumbersOnly(cellphone);
    }

    const validateAddress = () => {
        return stringIsNullOrEmpty(address);
    }

    const saveChangesClicked = async () => {
        if(validateFirstName() || validateLastName() || validateCellphone() || validateEmail() || 
            validateIDNumber() || validateAddress())
        {
            setMissingInfoDialog(true);
            return;
        }

        setShowLoading(true);

        const payload = {
            Name: firstName,
            Surname: lastName,
            IDNumber: idNumber,
            Email: email,
            Cellphone: cellphone,
            Address: address
        }
        const response = await UserService.UpdateUser(payload);
        if(!response || response.status !== 200 || response.data === false){
            setError(true);
            setShowLoading(false);
            return;
        }

        setProfileUpdated(true);
        setShowLoading(false);
    }

    return (
        <div className={containerStyleClasses}>
            <UIProvider>
                <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">My Profile</Typography>
                <BasicTextField id="firstName" variant={textFieldVariants.outlined} label="First Name" size={textFieldSizes.small} value={firstName} 
                        onChange={(event) => { setFirstName(event.target.value) }} validateText={validateFirstName}/>
                <BasicTextField id="lastName" variant={textFieldVariants.outlined} label="Last Name" size={textFieldSizes.small} value={lastName} 
                    onChange={(event) => { setLastName(event.target.value) }} validateText={validateLastName}/>
                <BasicTextField id="email" variant={textFieldVariants.outlined} label="Email" size={textFieldSizes.small} value={email} 
                    onChange={(event) => { setEmail(event.target.value) }} validateText={validateEmail}/>
                <BasicTextField id="idNumber" variant={textFieldVariants.outlined} label="ID Number" size={textFieldSizes.small} value={idNumber} 
                    onChange={(event) => { setIDNumber(event.target.value) }} validateText={validateIDNumber}/>
                <BasicTextField id="cellphone" variant={textFieldVariants.outlined} label="Cellphone" size={textFieldSizes.small} value={cellphone} 
                    onChange={(event) => { setCellphone(event.target.value) }} validateText={validateCellphone}/>
                <BasicTextField id="address" variant={textFieldVariants.outlined} label="Address" size={textFieldSizes.small} value={address} 
                    onChange={(event) => { setAddress(event.target.value) }} validateText={validateAddress}/>
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Save changes" 
                    onClick={saveChangesClicked} color="primary"/>
            </UIProvider>
            <div className="user-subscriptions-container">
                <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">Your subscriptions</Typography>
                <ReactTable data={subscriptions} columns={columns}/>
            </div>
            <br/>
            <br/>
            <AlertDialog open={missingInfoDialog} handleClose={setMissingInfoDialog} title="Missing Information">
                Please ensure all fields are completed correctly.  
            </AlertDialog>
            <AlertDialog open={profileUpdated} handleClose={setProfileUpdated} title="Success">
                Your profile has been updated successfully.
            </AlertDialog>
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            <AlertDialog open={cancelSubscriptionSuccess} handleClose={setCancelSubscriptionSuccess} title="Success">
                The selected subscription has successfully been cancelled.
            </AlertDialog>
            <ConfirmationDialog title="Cancel Subscription!" open={confirmCancelSubscription} handleClose={setConfirmCancelSubscription} handleAction={makeCancelSubscriptionRequest}>
                    Are you sure you wish to cancel your subscription? This cannot be undone once cancelled.
            </ConfirmationDialog>
            {showLoading && <LoadingOverlay />}
        </div>
    );
}

export default MyProfile;