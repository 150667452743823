import './App.css';
import { Routes, Route } from "react-router-dom";
import Login from './Components/Login/Login';
import SignUp from './Components/SignUp/SignUp';
import Header from "./Components/Header/Header";
import LandingPage from "./Components/LandingPage/LandingPage";
import { useEffect, useState } from 'react';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import TermsAndConditions from './Components/TermsAndConditions/TermsAndConditions';
import MyProfile from './Components/MyProfile/MyProfile';
import BusinessManager from './Components/BusinessManager/BusinessManager';
import UserService from './Shared/Services/user-service';
import userContextServiceInstance from './Shared/Services/user-context-service';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import WellnessActivities from './Components/WellnessActivities/WellnessActivities';
import { WELLNESS_ACTIVITIES_ENABLED } from './Shared/api-config';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    const unloadCallback = (event: any) => {
      const contextToken = userContextServiceInstance.getContextToken();
      UserService.logout({ContextToken: contextToken});

      userContextServiceInstance.clearUserContext();
    };
  
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <div className='app-container'>
      <Header loggedIn={loggedIn} setLogggedIn={setLoggedIn} firstName={firstName} lastName={lastName}/>
      <div className='app-routes'>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login setLogggedIn={setLoggedIn} setFirstName={setFirstName} setLastName={setLastName}/>}/>
          <Route path="/reset-password" element={<ResetPassword />}/>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/business-manager" element={<BusinessManager />} />
          {WELLNESS_ACTIVITIES_ENABLED && <Route path="/wellness-activities" element={<WellnessActivities />} />}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {loggedIn && <Route path="/my-profile" element={<MyProfile />} />}
          <Route path="/*" element={<LandingPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
