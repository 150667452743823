import { HTTP_VERB } from "../Enums/http-enums";
import HttpClient from "./HttpClient/http-client";
import { AxiosRequestConfig } from "axios";
import { API_HOST } from "../api-config";

const baseUrl = API_HOST + "/api/";

class BusinessSubscriptionService {
    static instance: BusinessSubscriptionService;
    client: typeof HttpClient;

    constructor(client: typeof HttpClient){
        if(!BusinessSubscriptionService.instance){
            this.client = client;
            BusinessSubscriptionService.instance = this;
            return BusinessSubscriptionService.instance;
        }

        this.client = BusinessSubscriptionService.instance.client;
        return BusinessSubscriptionService.instance;
    }

    async getActiveSubscriptions(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.POST,
            baseUrl: baseUrl,
            url: baseUrl + "BusinessSubscription/GetActive",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async getAllForUser(){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.GET,
            baseUrl: baseUrl,
            url: baseUrl + "BusinessSubscription/GetAllForUser",
            headers: {
                "Content-Type": "application/json"
            },
            data: null
        }

        return (await this.client.request(config));
    }

    async addItem(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.POST,
            baseUrl: baseUrl,
            url: baseUrl + "BusinessSubscription/AddItem",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async cancelSubscription(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.POST,
            baseUrl: baseUrl,
            url: baseUrl + "BusinessSubscription/CancelSubscription",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }
}

const businessSubscriptionServiceInstance = new BusinessSubscriptionService(HttpClient);
Object.freeze(businessSubscriptionServiceInstance);

export default businessSubscriptionServiceInstance;