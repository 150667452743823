import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import "./Help.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
// import IncomeTypesImage from "/Images/Help/Income_Types.png";
// import ExpenseTypesImage from "/Images/Help/Expense_Types.png";
// import InventoryTypesImage from "/Images/Help/Inventory_Types.png";

const Help = (props) => {
    const [expanded, setExpanded] = useState("");

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getGeneralContent = () => {
        return (
            <div className="accordion-content-container">
                <div><strong>Adding items:</strong></div>
                <div>
                    To add a new record, you can simply click in an empty row and add the required data, or if there are no empty rows you can 
                    click on the <strong>Add Row</strong> button located above each of the grids.
                </div>
                <br/>

                <div><strong>Deleting items:</strong></div>
                <div>
                    In order to delete rows, you must first select the rows you wish to remove by clicking on the row number of the row(s) you wish to remove. 
                    Once you have selected the rows, you can then click on the <strong>Delete Row</strong> button to remove those rows.
                </div>
                <br/>

                <div>Below is a video showing the adding and deleting of rows:</div>
                <video className="img-video" width="700" height="300" controls>
                    <source src={window.location.origin + "/Videos/Help/AddDeleteRows.mp4"} type="video/mp4"/>
                </video>
                <br/>

                <div><strong>Grid Row Select:</strong></div>
                <div>You can click the row number to put a row into select mode. Clicking anywhere else in the grid will deselect all rows.</div>
                <div>Below is an example image of selected rows:</div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Grid_Row_Select.png"} alt="sales" width="900" height="200"/>
                <br/>

                <div><strong>Grid Row Error State:</strong></div>
                <div>When adding or updating values in a row with the incorrect data type, the row will go into an error state where it is highlighted in red.</div>
                <div>Below is an example image of the error row state:</div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Grid_Row_Error.png"} alt="sales" width="900" height="200"/>
                <br/>

                <div><strong>Grid Row Incomplete:</strong></div>
                <div>
                    When adding a new row, the row will automatically be in a incomplete state where it is not saving that row until all the necessarry fields have been completed.
                    The grid row will be highlighted in a light orange to indicate the row is not saved yet.
                </div>
                <div>Below is an example image of the incomplete row state:</div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Grid_Row_Incomplete.png"} alt="sales" width="900" height="200"/>
                <br/>

            </div>
        );
    }

    const getSalesContent = () => {
        return (
            <div className="accordion-content-container">
                <div>This section can be used to capture all your sales for the month.</div>
                <div>
                    This grid contains columns for a Income Type, Description, Date, Amount, Quantity, Total and Comment.
                </div>
                <br/>
                <div>Below is the type of data required for each column:</div>
                <div><strong>Income Type:</strong> Select dropdown, based on income types</div>
                <div><strong>Description:</strong> Select dropdown, based on income types</div>
                <div><strong>Date:</strong> Date field in the format <strong>YYYY/MM/DD</strong></div>
                <div><strong>Amount:</strong> Numerical decimal number</div>
                <div><strong>Quantity:</strong> Numerical whole number</div>
                <div><strong>Total:</strong> Auto calculated, numerical decimal number</div>
                <div><strong>Comment:</strong> Text</div>
                <br/>

                <div>Below is a video showing how to add, update and delete items from the Sales grid.</div>
                <video className="img-video" width="700" height="300" controls>
                    <source src={window.location.origin + "/Videos/Help/AddDeleteRows.mp4"} type="video/mp4"/>
                </video>
                <br/>

                <div>
                    Below is an example picture of what the grid look like for Sales.
                </div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Sales.png"} alt="sales" width="1000" height="500"/>
            </div>
        );
    }

    const getOtherIncomeContent = () => {
        return (
            <div className="accordion-content-container">
               <div>This section can be used to capture all other income for the month.</div>
                <div>
                    This grid contains columns for a Income Type, Description, Date, Amount and Comment.
                </div>
                <br/>
                <div>Below is the type of data required for each column:</div>
                <div><strong>Income Type:</strong> Select dropdown, based on income types</div>
                <div><strong>Description:</strong> Select dropdown, based on income types</div>
                <div><strong>Date:</strong> Date field in the format <strong>YYYY/MM/DD</strong></div>
                <div><strong>Amount:</strong> Numerical decimal number</div>
                <div><strong>Comment:</strong> Text</div>
                <br/>

                <div>Below is a video showing how to add, update and delete items from the Other Income grid.</div>
                <video className="img-video" width="700" height="300" controls>
                    <source src={window.location.origin + "/Videos/Help/Other_Income.mp4"} type="video/mp4"/>
                </video>
                <br/>
                
                <div>
                    Below is an example picture of what the grid look like for Other Income.
                </div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Other_Income.png"} alt="sales" width="1000" height="500"/>
            </div>
        );
    }

    const getCategoryTypesContent = () => {
        return (
            <div className="accordion-content-container">
                <div>There are three category groups namely, Income Types, Expense Types and Inventory Types.</div>
                <div>
                    These sections are for grouping the items for your incomes, expenses and inventory. There are two grids
                    in these sections.
                </div>
                <div>
                    One grid which contains one columns for the Category Types which can be added, editted 
                    or removed, and a read only column with the items within the category type.  
                </div>
                <div>
                    Once you click on the row for 
                    a category type, the second grid will contain one column where you can add, edit or remove the items within 
                    a category.
                </div>
                
                <br/>
                <div>Below is a video on the usage of the income, expense and stock types:</div>
                <video className="img-video" width="700" height="300" controls>
                    <source src={window.location.origin + "/Videos/Help/Category_Types_Video.mp4"} type="video/mp4"/>
                </video>
                <br/>

                <div>
                    Below are example pictures of what the grids look like for Income Types, Expense Types and Inventory types.
                </div>
                
                <div><strong>Income Types:</strong></div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Income_Types.png"} alt="income-types" width="1000" height="500"/>
                <br/>
                <div><strong>Expense Types:</strong></div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Expense_Types.png"} alt="expense-types" width="1000" height="500"/>
                <br/>
                <div><strong>Inventory Types:</strong></div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Inventory_Types.png"} alt="inventory-types" width="1000" height="500"/>
                <br/>
            </div>
        );
    }

    const getExpensesContent = () => {
        return (
            <div className="accordion-content-container">
                <div>This section can be used to capture all your expenses for the month.</div>
                <div>
                    This grid contains columns for a Expense Type, Description, Date, Amount, Quantity, Total and Comment.
                </div>
                <br/>
                <div>Below is the type of data required for each column:</div>
                <div><strong>Expense Type:</strong> Select dropdown, based on expense types</div>
                <div><strong>Description:</strong> Select dropdown, based on expense types</div>
                <div><strong>Date:</strong> Date field in the format <strong>YYYY/MM/DD</strong></div>
                <div><strong>Amount:</strong> Numerical decimal number</div>
                <div><strong>Quantity:</strong> Numerical whole number</div>
                <div><strong>Total:</strong> Auto calculated, numerical decimal number</div>
                <div><strong>Comment:</strong> Text</div>
                <br/>

                <div>Below is a video showing how to add, update and delete items from the Expenses grid.</div>
                <video className="img-video" width="700" height="300" controls>
                    <source src={window.location.origin + "/Videos/Help/Expenses.mp4"} type="video/mp4"/>
                </video>
                <br/>

                <div>
                    Below is an example picture of what the grid look like for Expenses.
                </div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Expenses.png"} alt="sales" width="1000" height="500"/>
            </div>
        );
    }

    const getInventoryContent = () => {
        return (
            <div className="accordion-content-container">
                <div>This section can be used to capture all your inventory for the month.</div>
                <div>
                    This grid contains columns for a Inventory Type, Description, Date, Amount, Quantity, Total and Comment.
                </div>
                <br/>
                <div>Below is the type of data required for each column:</div>
                <div><strong>Inventory Type:</strong> Select dropdown, based on inventory types</div>
                <div><strong>Description:</strong> Select dropdown, based on inventory types</div>
                <div><strong>Date:</strong> Date field in the format <strong>YYYY/MM/DD</strong></div>
                <div><strong>Amount:</strong> Numerical decimal number</div>
                <div><strong>Quantity:</strong> Numerical whole number</div>
                <div><strong>Total:</strong> Auto calculated, numerical decimal number</div>
                <div><strong>Comment:</strong> Text</div>
                <br/>

                <div>Below is a video showing how to add, update and delete items from the Inventory grid.</div>
                <video className="img-video" width="700" height="300" controls>
                    <source src={window.location.origin + "/Videos/Help/Inventory.mp4"} type="video/mp4"/>
                </video>
                <br/>

                <div>
                    Below is an example picture of what the grid look like for Inventory.
                </div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Inventory.png"} alt="sales" width="1000" height="500"/>
            </div>
        );
    }

    const getAssetsContent = () => {
        return (
            <div className="accordion-content-container">
                <div>This section can be used to capture all your assets in your business.</div>
                <div>
                    This grid contains columns for a Asset Type, Asset Description, Principle Value, Date of purchase, 
                    Interest Type, Compounding period, Interest rate p.a, Current Value, Comment.
                </div>
                <br/>
                <div>Below is the type of data required for each column:</div>
                <div><strong>Asset Type:</strong> Select dropdown for asset types</div>
                <div><strong>Asset Description:</strong> Select dropdown, based on asset type</div>
                <div><strong>Principle Value:</strong> Decimal number for the starting value of your asset</div>
                <div><strong>Date of purchase:</strong> Date field in the format <strong>YYYY/MM/DD</strong></div>
                <div><strong>Interest Type:</strong> Select dropdown to choose between none, simple interest, compound interest and depreciation</div>
                <div><strong>Compounding period:</strong> Select dropdown for the compounding period, only applicable to interest type of compound interest</div>
                <div><strong>Interest rate p.a:</strong> Decimal number for the interest rate per annum</div>
                <div><strong>Current Value:</strong> Auto calculated, numerical decimal number for the asset current value</div>
                <div><strong>Comment:</strong> Text</div>
                <br/>

                <div>Below is a video showing how to add, update and delete items from the Assets grid.</div>
                <video className="img-video" width="700" height="300" controls>
                    <source src={window.location.origin + "/Videos/Help/Assets.mp4"} type="video/mp4"/>
                </video>
                <br/>

                <div>
                    Below is an example picture of what the grid look like for Assets.
                </div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Assets.png"} alt="sales" width="1000" height="500"/>
            </div>
        );
    }

    const getLiabilitiesContent = () => {
        return (
            <div className="accordion-content-container">
                <div>This section can be used to capture all your liabilities in your business.</div>
                <div>
                    This grid contains columns for a Liability Type, Liability Description, Amount, Date and Comment.
                </div>
                <br/>
                <div>Below is the type of data required for each column:</div>
                <div><strong>Liability Type:</strong> Select dropdown for liability types</div>
                <div><strong>Liability Description:</strong> Select dropdown, based on liability type</div>
                <div><strong>Amount:</strong> Decimal number for the value of the liability</div>
                <div><strong>Date:</strong> Date field in the format <strong>YYYY/MM/DD</strong></div>
                <div><strong>Comment:</strong> Text</div>
                <br/>

                <div>Below is a video showing how to add, update and delete items from the Liabilities grid.</div>
                <video className="img-video" width="700" height="300" controls>
                    <source src={window.location.origin + "/Videos/Help/Liabilities.mp4"} type="video/mp4"/>
                </video>
                <br/>

                <div>
                    Below is an example picture of what the grid look like for Liabilities.
                </div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Liability.png"} alt="sales" width="1000" height="500"/>
            </div>
        );
    }

    const getOverviewContent = () => {
        return (
            <div className="accordion-content-container">
                <div>
                    The overview can be used to get a summary of all your sales, other income, expenses, 
                    inventory and assets over a period of time. 
                </div>
                <div>You can then also generate an income statement or balance sheet for your business for the time period you select.</div>

                <br/>
                <div>Below is a video showing how to use the overview and generate an income statment.</div>
                <video className="img-video" width="700" height="300" controls>
                    <source src={window.location.origin + "/Videos/Help/Overview.mp4"} type="video/mp4"/>
                </video>
                <br/>

                <div>
                    Below is an example picture of what the grids look like for Overview.
                </div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Overview.png"} alt="sales" width="1000" height="500"/>

                <br/>
                <div>
                    Below is an example picture of what the income statement looks like.
                </div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Income_Statement.png"} alt="sales" width="800" height="400"/>

                <br/>
                <div>
                    Below is an example picture of what the balance sheet looks like.
                </div>
                <img className="img-video" src={window.location.origin + "/Images/Help/Balance_Sheet.png"} alt="sales" width="800" height="800"/>
            </div>
        );
    }

    return (
        <div className="help-container">
            <Accordion sx={{ width: '100%' }} expanded={expanded === 'general'} onChange={handleChange('general')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography fontWeight="bold">General</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {getGeneralContent()}
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ width: '100%' }} expanded={expanded === 'category-types'} onChange={handleChange('category-types')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography fontWeight="bold">Category Types</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {getCategoryTypesContent()}
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ width: '100%' }} expanded={expanded === 'sales'} onChange={handleChange('sales')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography fontWeight="bold">Sales</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {getSalesContent()}
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ width: '100%' }} expanded={expanded === 'other-income'} onChange={handleChange('other-income')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography fontWeight="bold">Other Income</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {getOtherIncomeContent()}
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ width: '100%' }} expanded={expanded === 'expenses'} onChange={handleChange('expenses')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography fontWeight="bold">Expenses</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {getExpensesContent()}
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ width: '100%' }} expanded={expanded === 'inventory'} onChange={handleChange('inventory')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography fontWeight="bold">Inventory</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {getInventoryContent()}
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ width: '100%' }} expanded={expanded === 'assets'} onChange={handleChange('assets')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography fontWeight="bold">Assets</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {getAssetsContent()}
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ width: '100%' }} expanded={expanded === 'liabilities'} onChange={handleChange('liabilities')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography fontWeight="bold">Liabilities</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {getLiabilitiesContent()}
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ width: '100%' }} expanded={expanded === 'overview'} onChange={handleChange('overview')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography fontWeight="bold">Overview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {getOverviewContent()}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default Help;